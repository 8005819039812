<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="41" height="42" viewBox="0 0 41 42">
    <defs>
      <clipPath id="clip-path">
        <circle id="Ellipse_82" data-name="Ellipse 82" cx="18.5" cy="18.5" r="18.5" transform="translate(0.27 0.388)" fill="#fff" stroke="#707070" stroke-width="1"/>
      </clipPath>
    </defs>
    <g id="Group_10231" data-name="Group 10231" transform="translate(3680 -273)">
      <g id="Path_9268" data-name="Path 9268" transform="translate(-3678 278)" fill="#050253">
        <path d="M 18.5 36.5 C 13.69202041625977 36.5 9.171830177307129 34.62767028808594 5.772079944610596 31.22792053222656 C 2.372329950332642 27.82817077636719 0.5 23.30797958374023 0.5 18.5 C 0.5 13.69202041625977 2.372329950332642 9.171830177307129 5.772079944610596 5.772079944610596 C 9.171830177307129 2.372329950332642 13.69202041625977 0.5 18.5 0.5 C 23.30797958374023 0.5 27.82817077636719 2.372329950332642 31.22792053222656 5.772079944610596 C 34.62767028808594 9.171830177307129 36.5 13.69202041625977 36.5 18.5 C 36.5 23.30797958374023 34.62767028808594 27.82817077636719 31.22792053222656 31.22792053222656 C 27.82817077636719 34.62767028808594 23.30797958374023 36.5 18.5 36.5 Z" stroke="none"/>
        <path d="M 18.5 1 C 13.82558059692383 1 9.430940628051758 2.820320129394531 6.125629425048828 6.125629425048828 C 2.820320129394531 9.430940628051758 1 13.82558059692383 1 18.5 C 1 23.17441940307617 2.820320129394531 27.56906127929688 6.125629425048828 30.87437057495117 C 9.430940628051758 34.17967987060547 13.82558059692383 36 18.5 36 C 23.17441940307617 36 27.56906127929688 34.17967987060547 30.87437057495117 30.87437057495117 C 34.17967987060547 27.56906127929688 36 23.17441940307617 36 18.5 C 36 13.82558059692383 34.17967987060547 9.430940628051758 30.87437057495117 6.125629425048828 C 27.56906127929688 2.820320129394531 23.17441940307617 1 18.5 1 M 18.5 0 C 28.71726989746094 0 37 8.282730102539063 37 18.5 C 37 28.71726989746094 28.71726989746094 37 18.5 37 C 8.282730102539063 37 0 28.71726989746094 0 18.5 C 0 8.282730102539063 8.282730102539063 0 18.5 0 Z" stroke="none" fill="#050253"/>
      </g>
      <g id="Mask_Group_312" data-name="Mask Group 312" transform="translate(-3678.27 277.612)" clip-path="url(#clip-path)">
        <g id="Rectangle_2716" data-name="Rectangle 2716" transform="translate(6.27 22.388)" fill="#fff" stroke="#050253" stroke-width="1">
          <rect width="25" height="17" rx="8.5" stroke="none"/>
          <rect x="0.5" y="0.5" width="24" height="16" rx="8" fill="none"/>
        </g>
      </g>
      <g id="Rectangle_2711" data-name="Rectangle 2711" transform="translate(-3644 280)" fill="#fff" stroke="#050253" stroke-width="1">
        <rect width="5" height="11" rx="2.5" stroke="none"/>
        <rect x="0.5" y="0.5" width="4" height="10" rx="2" fill="none"/>
      </g>
      <g id="Rectangle_2715" data-name="Rectangle 2715" transform="translate(-3680 280)" fill="#fff" stroke="#050253" stroke-width="1">
        <rect width="5" height="11" rx="2.5" stroke="none"/>
        <rect x="0.5" y="0.5" width="4" height="10" rx="2" fill="none"/>
      </g>
      <g id="Path_9269" data-name="Path 9269" transform="translate(-3678 278)" fill="none">
        <path d="M18.5,0A18.5,18.5,0,1,1,0,18.5,18.5,18.5,0,0,1,18.5,0Z" stroke="none"/>
        <path d="M 18.5 1 C 13.82558059692383 1 9.430940628051758 2.820320129394531 6.125629425048828 6.125629425048828 C 2.820320129394531 9.430940628051758 1 13.82558059692383 1 18.5 C 1 23.17441940307617 2.820320129394531 27.56906127929688 6.125629425048828 30.87437057495117 C 9.430940628051758 34.17967987060547 13.82558059692383 36 18.5 36 C 23.17441940307617 36 27.56906127929688 34.17967987060547 30.87437057495117 30.87437057495117 C 34.17967987060547 27.56906127929688 36 23.17441940307617 36 18.5 C 36 13.82558059692383 34.17967987060547 9.430940628051758 30.87437057495117 6.125629425048828 C 27.56906127929688 2.820320129394531 23.17441940307617 1 18.5 1 M 18.5 0 C 28.71726989746094 0 37 8.282730102539063 37 18.5 C 37 28.71726989746094 28.71726989746094 37 18.5 37 C 8.282730102539063 37 0 28.71726989746094 0 18.5 C 0 8.282730102539063 8.282730102539063 0 18.5 0 Z" stroke="none" fill="#050253"/>
      </g>
      <g id="Rectangle_2707" data-name="Rectangle 2707" transform="translate(-3678 273)" fill="#fff" stroke="#050253" stroke-width="1">
        <rect width="37" height="26" rx="13" stroke="none"/>
        <rect x="0.5" y="0.5" width="36" height="25" rx="12.5" fill="none"/>
      </g>
      <g id="Rectangle_2708" data-name="Rectangle 2708" transform="translate(-3674 278)" fill="#f93549" stroke="#050253" stroke-width="1">
        <rect width="29" height="16" rx="8" stroke="none"/>
        <rect x="0.5" y="0.5" width="28" height="15" rx="7.5" fill="none"/>
      </g>
      <circle id="Ellipse_80" data-name="Ellipse 80" cx="2" cy="2" r="2" transform="translate(-3667 282)" fill="#fff"/>
      <path id="Path_9270" data-name="Path 9270" d="M2,0A2,2,0,1,1,0,2,2,2,0,0,1,2,0Z" transform="translate(-3656 282)" fill="#fff"/>
      <path id="Path_9267" data-name="Path 9267" d="M-3525.4,276.5a2.648,2.648,0,0,0,2.069,1.131,2.415,2.415,0,0,0,1.912-1.131" transform="translate(-136.436 12.932)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "botHelpButton"
}
</script>