import appConfig from '@/config-app.js'

import store from '@/store/index'

// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export default {
  getVariationPrice (brand, filters) {
    let params = {}
    if(filters.channel.id){
      params.channel = filters.channel.id
    }
    if(filters.province && filters.province.province_id){
      params.province = filters.province.province_id
    }
    if(filters.city && filters.city.city_id){
      params.city = filters.city.city_id
    }
    if(filters.municipality && filters.municipality.municipality_id){
      params.municipality = filters.municipality.municipality_id
    }    
    if(filters.family){
      params.family = filters.family.id
    }
    if(filters.variation_type){
      params.variation_type = filters.variation_type
    }
    params.country = window.localStorage.getItem('_country_selected')
    axios.defaults.headers.common['Authorization'] = 'Token ' + store.state.user.hasToken
    return new Promise((resolve, reject) => {
      axios.get('/menu-item-price-variation-ranking/'+ brand + '/',{ params })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getMenuItems (brand, filters) {
    let params = {}
    if(filters.province && filters.province.province_id){
      params.province = filters.province.province_id
    }
    if(filters.city && filters.city.city_id){
      params.city = filters.city.city_id
    }
    if(filters.municipality && filters.municipality.municipality_id){
      params.municipality = filters.municipality.municipality_id
    }
    if(filters.channel && filters.channel.id){
      params.channel = filters.channel.id
    }
    if(filters.aggregator && filters.aggregator.id){
      params.delivery_platform = filters.aggregator.id
    }
    if(filters.family){
      params.family = filters.family.id
    }
    params.country = window.localStorage.getItem('_country_selected')
    return new Promise((resolve, reject) => {
      axios.get('/menu-item/'+ brand + '/',{ params })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
           reject(error.response)
        })
    })
  },
  getAggregatorsRanking (brand, filters) {
    let params = {}
    if(filters.province.province_id){
      params.province = filters.province.province_id
    }
    if(filters.city && filters.city.city_id){
      params.city = filters.city.city_id
    }
    if(filters.municipality && filters.municipality.municipality_id){
      params.municipality = filters.municipality.municipality_id
    }
    if(filters.family){
      params.family = filters.family.id
    }
    params.country = window.localStorage.getItem('_country_selected')
    return new Promise((resolve, reject) => {
      axios.get('/menu-item-per-delivery-platform/'+ brand + '/',{ params })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
           reject(error.response)
        })
    })
  },
  getModaldata (data) {
    let params = {}
    if(data.PROVINCE_ID){
      params.province = data.PROVINCE_ID
    }
    if(data.DELIVERY_PLATFORM_ID){
      params.delivery_platform = data.DELIVERY_PLATFORM_ID
    }
    if(data.CHANNEL){
      params.channel = data.CHANNEL.id
    }
    if(data.variation_type){
      params.variation_type = data.variation_type
    }

    
    params.country = window.localStorage.getItem('_country_selected')
    return new Promise((resolve, reject) => {
      axios.get('/menu-item-price-variation-history/'+ data.BRAND_ID + '/' + data.MENU_ITEM_ID + '/',{params})
        .then(res => {
          resolve(res)
        })
        .catch(error => {
           reject(error.response)
        })
    })
  },
  getProvincesRanking (brand, filters) {
    let params = {}
    /*if(filters.province.province_id){
      params.province = filters.province.province_id
    }
    if(filters.city && filters.city.city_id){
      params.city = filters.city.city_id
    }
    if(filters.municipality && filters.municipality.municipality_id){
      params.municipality = filters.municipality.municipality_id
    }*/
    if(filters.family){
      params.family = filters.family.id
    }
    if(filters.channel.id){
      params.channel = filters.channel.id
    }
    if(filters.aggregator.id){
      params.delivery_platform = filters.aggregator.id
    }
    params.country = window.localStorage.getItem('_country_selected')
    return new Promise((resolve, reject) => {
      axios.get('/menu-item-per-city/'+ brand + '/',{ params })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
           reject(error.response)
        })
    })
  },

  
}