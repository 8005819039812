<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63" viewBox="0 0 63 63">
    <g id="arrow-right-circle" transform="translate(-1 -1)">
      <circle id="Ellipse_2" data-name="Ellipse 2" cx="30.5" cy="30.5" r="30.5" transform="translate(2 2)" fill="#fff" stroke="#4a90e2" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <path id="Path_4787" data-name="Path 4787" d="M12,32.4,24.2,20.2,12,8" transform="translate(20.5 12.3)" fill="none" stroke="#4a90e2" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <line id="Line_1043" data-name="Line 1043" x2="25" transform="translate(20 33)" fill="none" stroke="#4a90e2" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "svgNextButton"
}
</script>

<style scoped>

</style>