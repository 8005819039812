<template lang="pug">
div
  gp-filter-top#filtersFixed(@getData="getIpj")
  v-container.gastroprice-content.d-flex.flex-column(fluid)
    .block-content.row
      v-container.table-data.ma-5.pa-0(v-if="isLoading" fluid)
        v-skeleton-loader(type="table")
      v-container.table-data.ma-5.pa-0(v-if="!isLoading" fluid)
        .table-title.pa-4
          p.mb-0.pt-3 #[translate IPJ - Adjusted Price Index]
        .table-content
          .header.d-flex(v-if="ipjData.table")
            v-col(v-for="(item, index) in ipjData.table.header" :class="{'myRestaurant': index == 1, 'first-col': index === 0, 'col-average': index == ipjData.table.header.length - 1}")
              .block-img(v-if="item" :style="{ backgroundImage: 'url(' + getImg(item.logo) + ')' }")
          .subheader.d-flex(v-if="ipjData.table")
            v-col.pa-0.pt-1.first-col
            v-col.pa-0.pt-1(v-if="index > 0 && index < ipjData.table.header.length - 1" :class="{'myRestaurant': index == 1}" v-for="(item, index) in ipjData.table.header")
            v-col.pa-0.pt-1.col-average
              p.mb-1 #[translate Average (M)]
          .content-item.d-flex.align-stretch(v-if="ipjDataRows && index + 2 < ipjDataRows.length" v-for="(ipj, index) in ipjDataRows")
            v-col.d-flex.align-center.first-col
              p.mb-0 {{titleRow[ipj[0]]}}
            v-col.d-flex.align-center.justify-center(v-if="i > 0" v-for="(item, i) in ipj" :class="{ 'myRestaurant': i == 1 , 'col-average': i == ipj.length - 1}")
              p.mb-0(v-if="i !== ipj.length - 1") {{item | formatNumber}}
                span(v-if="item !== null && index > 1 && i > 0 && i < ipj.length - 1") &nbsp; €
              p.mb-0(v-if="i === ipj.length - 1 && index !== 1") {{item | formatNumber}}
                span(v-if="item !== null && index > 1") &nbsp; €
          .content-price.d-flex.align-stretch
            v-col.d-flex.justify-center.align-center.first-col
              p.mb-0(v-if="ipjDataRows[lineExtraInfo]") {{titleRow[ipjDataRows[lineExtraInfo][0]]}}
            v-col.d-flex.justify-center.align-center(v-if="index > 0" v-for="(item, index) in ipjDataRows[lineExtraInfo]" :class="{'myRestaurant': index == 1, 'col-average': index == ipjData.table.header.length - 1, 'col-deviation': index == ipjData.table.header.length - 1}")
              p.mb-0(v-if="index !== ipjDataRows[lineExtraInfo].length - 1" :class="{'font-weight-bold': index > 0}") {{item | formatNumber}}
                span(v-if="item !== null && index > 0") &nbsp; €
          .content-deviation.d-flex.align-stretch
            v-col.d-flex.justify-center.align-center.first-col.font-weight-medium
              p.mb-0(v-if="ipjDataRows[lineExtraInfo + 1]") {{titleRow[ipjDataRows[lineExtraInfo + 1][0]]}}
            v-col.d-flex.justify-center.align-center(v-if="index > 0" v-for="(item, index) in ipjDataRows[lineExtraInfo + 1]" :class="{'myRestaurant': index == 1, 'col-average': index == ipjData.table.header.length - 1, 'col-deviation': index == ipjData.table.header.length - 1}")
              p.mb-0(v-if="index !== ipjDataRows[lineExtraInfo + 1].length - 1" :class="{'font-weight-bold': index > 0}") {{item | formatNumber}}
                span(v-if="item !== null && index > 0 ") &nbsp; %
    .block-explication.row
      v-col.d-flex.justify-end.col-img
        .block-img
          v-img(:src="img.ico_data_tooltip" width="91" height="73")
      v-col.d-flex.flex-wrap
        v-col.d-flex.badge-item.my-2(cols="11")
          v-col(cols="12")
            p.font-weight-medium #[translate El análisis comparativo en base a la calidad percibida en GastroRanking indica que es posible incrementar los precios un] 1,2%
</template>
<script>
import GpFilterTop from '@/components/dashboard/filter-top.vue'
import SrvDashboard from '@/services/dashboard.js'

export default {
  components: {GpFilterTop},
  name: 'ipj',
  data() {
    return {
      ipjData: [],
      ipjDataRows: [],
      lineExtraInfo: '',
      img: {
        ico_data_tooltip : require('@/assets/img/ico_data_tooltip.png'),
        no_img: require('@/assets/img/logos/restaurant_icon.png')
      },
      titleRow:{
        'GR_SCORE': this.$gettext('Quality Index - GastroRanking'),
        'QUALITY_INDEX': this.$gettext('Quality Evaluation Index'),
        'MEAN_TICKET': this.$gettext('Standard Average Consumption Ticket'),
        'IPJ': this.$gettext('Adjusted IPJ price'),
        'DEVIATION_PCT': this.$gettext('Deviation')
      },
      titleFilter: this.$gettext('Adjusted price index for standard consumption based on perceived quality in Gastroranking'),
    }
  },
  created(){
    this.$store.dispatch('loadMixPanelEvent')
    if(!this.$store.getters.hasToken) {
      this.$store.dispatch('loadToken')
    }
    this.$store.commit('TITLE_HEADER', this.titleFilter)
    this.$store.commit('HAS_MENU', '')
  },
  computed: {
    isLoading(){
      return this.$store.getters.isLoading
    }
  },
  methods: {
    getIpj(data){
      this.$store.commit('LOADING', true)
      SrvDashboard.getDashData('ipj', data.brand, data.filters)
      .then(res => {
          if (res.status === 200) {
            this.ipjData = res.data
            this.ipjDataRows = res.data.table.rows
            this.lineExtraInfo = this.ipjDataRows.length - 2
            this.$store.commit('LOADING', false)
          } 
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    getImg(img){
      return img ? img : this.img.no_img
    },
  },
  mounted () {
    let elementBody = document.querySelector('.gastroprice-content')
    elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight) + 'px;')
    this.$nextTick(() =>{
      window.addEventListener('resize', function () {
        let elementBody = document.querySelector('.gastroprice-content')
        elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight) + 'px;')
      })
    })
  }
}
</script>
<style lang="scss" scoped>
  .gastroprice-content{
     .table-content{
        font-size: 14px;
        .first-col{
          flex: 0 0 25%;
          max-width: 25%;
        }
      .content-item{
        border-bottom: 1px solid #D9DFE8;
        .myRestaurant{
          font-weight: 500;
        }
      }
      .content-price{
        height: 60px;
        border-top: 1px solid #9B9B9B;
        border-bottom: 1px solid #D9DFE8;
        background-color: #4a91e207;
        font-size: 15px;
        .first-col{
          justify-content: flex-end !important;
        }
      }
      .content-deviation{
        height: 60px;
        border-bottom: 1px solid #D9DFE8;
        font-size: 15px;
        .first-col{
          justify-content: flex-end !important;
        }
      }
    }
    
  }

</style>