var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-top d-flex flex-wrap"},[_c('v-col',{staticClass:"col-brand pt-0 pl-0"},[_c('v-select',{staticClass:"pt-0",attrs:{"items":_vm.brands,"placeholder":_vm.placeholder.brand,"color":"terciary","item-color":"#ECF2F9","menu-props":{offsetY: true }},on:{"change":_vm.changeBrand},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('img',{attrs:{"src":item.logo,"width":"41","height":"22"}}),_c('span',{staticClass:"terciary--text text-uppercase"},[_vm._v(_vm._s(item.name))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"mr-2 mb-2 img-option",attrs:{"src":item.logo,"width":"79","height":"42"}}),_c('span',{staticClass:"option-filter-brand"},[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.brandSelect),callback:function ($$v) {_vm.brandSelect=$$v},expression:"brandSelect"}},[_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"terciary"}},[_vm._v("mdi-chevron-down")])],1)],2)],1),(_vm.type !== 'provincesRank')?_c('v-col',{staticClass:"col-province pt-0 pl-0"},[_c('v-select',{staticClass:"pt-0",attrs:{"items":_vm.provinces,"item-text":"province_name","return-object":"","placeholder":_vm.placeholder.province,"no-data-text":"no hay provincias para esta marca","color":"terciary","item-color":"#ECF2F9","menu-props":{offsetY: true },"disabled":!_vm.brandSelect,"clearable":""},on:{"change":function($event){return _vm.changeFilter('province')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',{staticClass:"terciary--text text-uppercase text-elipsis"},[_vm._v(_vm._s(item.province_name))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"option-filter",class:{'selected' : _vm.filters.province.province_name === item.province_name}},[_vm._v(_vm._s(item.province_name))])]}}],null,false,363050259),model:{value:(_vm.filters.province),callback:function ($$v) {_vm.$set(_vm.filters, "province", $$v)},expression:"filters.province"}},[_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"terciary"}},[_vm._v("mdi-chevron-down")])],1)],2)],1):_vm._e(),(_vm.type !== 'provincesRank')?_c('v-col',{staticClass:"col-location pt-0 pl-0"},[_c('v-select',{staticClass:"pt-0",attrs:{"items":_vm.cities,"item-text":"city_name","return-object":"","placeholder":_vm.placeholder.location,"no-data-text":"no hay ciudades para esta provincia","color":"terciary","item-color":"#ECF2F9","menu-props":{offsetY: true },"disabled":!_vm.filters.province,"clearable":""},on:{"change":function($event){return _vm.changeFilter('city')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',{staticClass:"terciary--text text-uppercase text-elipsis"},[_vm._v(_vm._s(item.city_name))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"option-filter"},[_vm._v(_vm._s(item.city_name)+" ("+_vm._s(item.center_count)+")")])]}}],null,false,3083582842),model:{value:(_vm.filters.city),callback:function ($$v) {_vm.$set(_vm.filters, "city", $$v)},expression:"filters.city"}},[_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"terciary"}},[_vm._v("mdi-chevron-down")])],1)],2)],1):_vm._e(),(_vm.type !== 'provincesRank')?_c('v-col',{staticClass:"col-cp pt-0 pl-0"},[_c('v-select',{staticClass:"pt-0",attrs:{"items":_vm.cps,"item-text":"municipality_postal_code","return-object":"","placeholder":_vm.placeholder.postal_code,"no-data-text":"no hay códigos postales para esta ciudad","color":"terciary","item-color":"#ECF2F9","menu-props":{offsetY: true },"disabled":!_vm.filters.city,"clearable":""},on:{"change":function($event){return _vm.changeFilter('municipality')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',{staticClass:"terciary--text text-uppercase"},[_vm._v(_vm._s(item.municipality_postal_code))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"option-filter"},[_vm._v(_vm._s(item.municipality_postal_code))])]}}],null,false,1363533496),model:{value:(_vm.filters.municipality),callback:function ($$v) {_vm.$set(_vm.filters, "municipality", $$v)},expression:"filters.municipality"}},[_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"terciary"}},[_vm._v("mdi-chevron-down")])],1)],2)],1):_vm._e(),_c('v-col',{staticClass:"col-family pt-0 pl-0"},[_c('v-select',{staticClass:"pt-0",attrs:{"items":_vm.families,"item-text":"name","return-object":"","placeholder":_vm.placeholder.family,"no-data-text":"no hay familias","color":"terciary","item-color":"#ECF2F9","menu-props":{offsetY: true },"clearable":""},on:{"change":function($event){return _vm.changeFilter('family')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item)?_c('span',{staticClass:"terciary--text text-uppercase text-elipsis"},[_vm._v(_vm._s(item.name))]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [(item)?_c('span',{staticClass:"option-filter",class:{'selected' : _vm.filters.family.name === item.name}},[_vm._v(_vm._s(item.name))]):_vm._e()]}}]),model:{value:(_vm.filters.family),callback:function ($$v) {_vm.$set(_vm.filters, "family", $$v)},expression:"filters.family"}},[_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"terciary"}},[_vm._v("mdi-chevron-down")])],1)],2)],1),(_vm.type!=='aggregators')?_c('v-col',{staticClass:"col-channel pt-0 pl-0"},[_c('v-select',{staticClass:"pt-0",attrs:{"items":_vm.channels,"item-text":"name","return-object":"","placeholder":_vm.placeholder.channel,"color":"terciary","item-color":"#ECF2F9","menu-props":{offsetY: true },"disabled":!_vm.brandSelect,"clearable":""},on:{"change":function($event){return _vm.changeFilter('channel')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',{staticClass:"terciary--text text-uppercase text-elipsis"},[_vm._v(_vm._s(item.name))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"option-filter"},[_vm._v(_vm._s(item.name))])]}}],null,false,3868601297),model:{value:(_vm.filters.channel),callback:function ($$v) {_vm.$set(_vm.filters, "channel", $$v)},expression:"filters.channel"}},[_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"terciary"}},[_vm._v("mdi-chevron-down")])],1)],2)],1):_vm._e(),(_vm.type==='comparator' || _vm.type === 'provincesRank')?_c('v-col',{staticClass:"col-aggregator pt-0 pl-0"},[_c('v-select',{staticClass:"pt-0",attrs:{"items":_vm.aggregators,"item-text":"name","return-object":"","placeholder":_vm.placeholder.Aggregator,"color":"terciary","item-color":"#ECF2F9","menu-props":{offsetY: true },"disabled":!_vm.brandSelect || _vm.filters.channel.name !== 'Delivery',"clearable":""},on:{"change":function($event){return _vm.changeFilter('aggregator')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',{staticClass:"terciary--text text-uppercase"},[_vm._v(_vm._s(item.name))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"option-filter"},[_vm._v(_vm._s(item.name))])]}}],null,false,842521720),model:{value:(_vm.filters.aggregator),callback:function ($$v) {_vm.$set(_vm.filters, "aggregator", $$v)},expression:"filters.aggregator"}},[_c('template',{slot:"append"},[_c('v-icon',{attrs:{"color":"terciary"}},[_vm._v("mdi-chevron-down")])],1)],2)],1):_vm._e(),(_vm.type==='comparator' || _vm.type === 'provincesRank')?_c('v-col',{staticClass:"col-aggregator pt-0 pl-0"},[_c('filter-search')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }