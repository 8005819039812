/* COMMON */
export const LOADING = 'LOADING' // IS LOADING
export const TITLE_HEADER = 'TITLE_HEADER' // TITLE FOR HEADER
export const HAS_MENU = 'HAS_MENU' // SHOW MENU IN HEADER
export const COUNTRIES = 'COUNTRIES' // SHOW COUNTRIES HEADER
export const COUNTRY_SELECTED = 'COUNTRY_SELECTED' // COUNTRI ACTIVE

/* Auth - USER */
export const IS_LOGIN = 'IS_LOGIN' // USER LOGIN

// FILTERS
export const BRANDS = 'BRANDS' // brand options
export const BRAND_SELECT = 'BRAND_SELECT' // brand option selected
export const PROVINCES = 'PROVINCES' // PROVINCE OPTIONS
export const PROVINCE_SELECT = 'PROVINCE_SELECT' // PROVINCE OPTION SELECTED
export const FAMILIES = 'FAMILIES' // PROVINCE OPTIONS
export const FAMILY_SELECT = 'FAMILY_SELECT' // PROVINCE OPTION SELECTED
export const CITIES = 'CITIES' // CITIES OPTIONS
export const CITY_SELECT = 'CITY_SELECT' // CITIES OPTION SELECTED
export const POSTAL_CODES = 'POSTAL_CODES' // POSTAL CODE OPTIONS
export const POSTAL_CODE_SELECT = 'POSTAL_CODE_SELECT' // POSTAL CODE OPTION SELECTED
export const MOMENTS = 'MOMENTS' // MOMENTS SELECTED
export const MOMENT_SELECT = 'MOMENT_SELECT' // MOMENTS SELECTED
export const CHANNELS = 'CHANNELS' // MOMENTS SELECTED
export const CHANNEL_SELECT = 'CHANNEL_SELECT' // MOMENTS SELECTED
export const AGGREGATORS = 'AGGREGATORS' // AGGREGATORS SELECTED
export const AGGREGATOR_SELECT = 'AGGREGATOR_SELECT' // AGGREGATORS SELECTED
export const SEARCH_TEXT = 'SEARCH_TEXT' // SEARCH BY TEXT