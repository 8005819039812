import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#010252',
        secondary: '#050253',
        terciary: '#4A90E2',
        accent: '#8c9eff',
        error: '#b71c1c',
        gp_black: '#161719',
        gp_gray: '#161616',
        background: '#04043A',
        pink_app: '#F93549',
        bg_top: '#1AAA55',
        bg_medium: '#FC9403',
        bg_low: '#DB3B21',
      },
    },
  },
});
