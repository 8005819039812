<template lang="pug">
  div
    gp-filter-top-analysis#filtersFixed(@getData="getVariationPrice" :type="'variations'")
    v-container.gastroprice-content.with-menu.d-flex.flex-column(fluid)
      .block-content.row
        v-col(v-if="isLoading" cols="12")
          v-skeleton-loader.mr-9(type="table")
        v-col(v-if="!isLoading" cols="12")
          .d-flex.flex-wrap(style="position: relative;")
            .block-content-tab.ml-5.pa-5(style="position: relative; margin-top: 3em;")
              //- decrement-variation(v-if="activeTab === 'decrement'" :data="data.decrement")
              table-variation(v-if="activeTab === 'decrement'" :data="dataDecrement" sortDefault="asc" @showModal="showModal")
              //- increment-variation(v-if="activeTab === 'increment'" :data="data.increment")
              table-variation(v-if="activeTab === 'increment'" :data="dataIncrement" sortDefault="desc" @showModal="showModal")
            .block-tabs.ml-5.mt-2.d-flex(style="position: fixed; z-index: 1;")
              span(style="position: fixed; top: 0; left: 0; width: 100%; height: 256px; background-color: aliceblue;")
              span.gp-tab.mr-3.color-red(style="z-index: 300;" @click="setTab('decrement')" :class="{'tab-active' : activeTab === 'decrement'}") #[img(:src="img.arrowDown" width="10" )] Mayores Bajadas
              span.gp-tab.color-green(style="z-index: 300;" @click="setTab('increment')" :class="{'tab-active' : activeTab === 'increment'}") #[img(:src="img.arrowUp" width="10" )]  Mayores Subidas
        v-dialog( v-model="dialog"
        hide-overlay
        width="1100")
          v-card
            v-card-text
              v-container.pt-12
                img.close-modal.cursor-pointer(:src="img.close" @click="dialog=false")
                v-row
                  v-col(cols="7")
                    .row
                      v-col(cols="6")
                        translate.black--text Plate
                        span.ml-4.primary--text.font-weight-med {{infoModal.plate}}
                      v-col(cols="6")
                        translate.black--text Brand
                        span.ml-4.primary--text.font-weight-med {{infoModal.brand}}
                      v-col(cols="6")
                        translate.black--text Province
                        span.ml-4.primary--text.font-weight-med {{infoModal.province}}
                      v-col(cols="6")
                        translate.black--text Channel
                        span.ml-4.primary--text.font-weight-med {{infoModal.aggregator}}
                    v-divider.mt-3
                    .d-flex
                      v-col.px-0
                        p.black--text.modal-small-text #[translate Yesterday price]
                        span.modal-big-text.font-weight-med.primary--text {{infoModal.yesterday_price | formatNumber(2,2)}} €
                      v-col
                        p.black--text.modal-small-text #[translate Today price]
                        span.modal-big-text.font-weight-med.primary--text {{infoModal.today_price | formatNumber(2,2)}} €
                      v-col
                        p.black--text.modal-small-text #[translate Variation]
                        img.mr-2(v-if="infoModal.variation_pct < 0" :src="img.arrowDown")
                        img.mr-2(v-if="infoModal.variation_pct > 0" :src="img.arrowUp")
                        span.modal-big-text.font-weight-med(:class="{'color-red': infoModal.variation_pct < 0,'color-green': infoModal.variation_pct > 0 }") {{infoModal.variation_pct | formatNumber(0,1)}} %
                      v-col
                        p.black--text.modal-small-text #[translate Deviation typical]
                        span.modal-big-text.font-weight-med.primary--text {{infoModal.variation  | formatNumber(0,2)}} %
                  v-col.border-in-modal(cols="5")
                    div#canvas-line(style="position: relative; height: 250px")
                      .block-title.text-center
                        translate.black--text.font-weight-med Historic Variation
                      translate.black--text price
                      line-chart.mt-3(v-if="dialog" :chart-data="dataChartSet" :options="optionsChart" height="200")
            #tableVariationsModal
              div
                .header.row
                  v-col.border-col-rigth.pt-3.pb-0(cols="3")
                    translate Restaurant
                  v-col.border-col-rigth.pt-3.pb-0(cols="2")
                    translate Direction
                  v-col.border-col-rigth.pt-3.pb-0(cols="2")
                    translate Localidad
                  v-col.border-col-rigth.pt-3.pb-0
                    translate Code Postal
                  v-col.border-col-rigth.pt-3.pb-0.text-center
                    translate Price yesterday
                  v-col.border-col-rigth.pt-3.pb-0.text-center
                    translate Price today
                  v-col.border-col-rigth.pt-3.pb-0.text-center
                    translate Variation
                div.container.pr-0.pl-0(v-if="dataModal")
                  //.row.border-b-gray(v-for="(row, i) in dataModal.table.rows"  :class="{'bg-ligth-gray': i % 2 != 0}")
                    v-col.border-col-rigth.pt-3.pb-0(cols="3")
                      translate Restaurant
                    v-col.border-col-rigth.pt-3.pb-0(cols="2")
                      translate Direction
                    v-col.border-col-rigth.pt-3.pb-0(cols="2")
                      translate Localidad
                    v-col.border-col-rigth.pt-3.pb-0
                      translate Code Postal
                    v-col.border-col-rigth.pt-3.pb-0.text-center
                      translate Price yesterday
                    v-col.border-col-rigth.pt-3.pb-0.text-center
                      translate Price today
                    v-col.border-col-rigth.pt-3.pb-0.text-center
                      translate Variation
                  div.container.pr-0.pl-0(v-if="dataModal")
                    .row.border-b-gray(v-for="(row, i) in dataModal.table.rows"  :class="{'bg-ligth-gray': i % 2 != 0}")
                      v-col.border-col-rigth.pt-3.pb-0(cols="3")
                        span {{ row[1] }}
                      v-col.border-col-rigth.pt-3.pb-0(cols="2")
                        span {{ row[2] }}
                      v-col.border-col-rigth.pt-3.pb-0(cols="2")
                        span {{ row[3] }}
                      v-col.border-col-rigth.pt-3.pb-0
                        span {{ row[4] }}
                      v-col.border-col-rigth.pt-3.pb-0.text-center
                        span {{ row[5] }}
                      v-col.border-col-rigth.pt-3.pb-0.text-center
                        span {{ row[6] }}
                      v-col.border-col-rigth.pt-3.pb-0.text-center
                        span {{ row[7] | formatNumber }} %


</template>
<script>
import GpFilterTopAnalysis from '@/components/dashboard/filter-top-analysis.vue'
import SrvRateAnalysis from '@/services/rateAnalysis.js'
import TableVariation from '@/components/dashboard/priceVariation/tableVariation.vue'
import lineChart from '@/components/dashboard/chart/lineChart.vue'

export default {
  components: {GpFilterTopAnalysis,TableVariation,lineChart},
  name: 'priceVariations',
  data() {
    return {
      activeTab: 'decrement',
      data:{
        decrement: [],
        increment: []
      },
      dataModal: null,
      infoModal: {},
      filterData:{
        filters: {}
      },
      img:{
        arrowDown: require('@/assets/img/arrowDown.png'),
        arrowUp: require('@/assets/img/arrowUp.png'),
        close: require('@/assets/img/plus.png')
      },
      dialog: false,
      dataChart: {
        labels: ["Nov.",	"Dic.",	"Ene.",	"Feb.",	"Mar."],
        datasets: [{
            label: 'Precio', // Name the series
            data: [14, 15, 12, 14, 13], // Specify the data values array
            fill: false,
            borderColor: '#4A90E2', // Add custom color border (Line)
            backgroundColor: '#4A90E2', // Add custom color background (Points and Fill)
            borderWidth: 3 // Specify bar border width
        }]},
      optionsChart: {
        responsive: false,
        legend: {
            display: false
        },
        scales: {
          yAxes: [{
            ticks: {
              callback: (value) => {
                return `${value} €`;
              },
            },
          }],
        }
      }
    }
  },
  created(){
    this.$store.dispatch('loadMixPanelEvent')
    if(!this.$store.getters.hasToken) {
      this.$store.dispatch('loadToken')
    }

  },
  computed: {
    dataChartSet () {
      let labelsDataPrice = []
      let dataPrice = []
      for(let  price in this.dataModal.graph) {
        labelsDataPrice.push(this.dataModal.graph[price][0])
        dataPrice.push(this.dataModal.graph[price][1])
      }
      return {
        labels: labelsDataPrice,
        datasets: [{
            label: 'Precio', // Name the series
            data: dataPrice, // Specify the data values array
            fill: false,
            borderColor: '#4A90E2', // Add custom color border (Line)
            backgroundColor: '#4A90E2', // Add custom color background (Points and Fill)
            borderWidth: 3 // Specify bar border width
        }]
      }  
    },
    isLoading(){
      return this.$store.getters.isLoading
    },
    dataDecrement () {
      return this.data.decrement
    },
    dataIncrement () {
      return this.data.increment
    }

  },
  methods: {
    setTab(type){
      this.activeTab = type
      this.getVariationPrice(this.filterData)
    },
    getVariationPrice(data){
        this.$store.commit('LOADING', true)
        data.filters.variation_type=this.activeTab
        this.filterData = data
        SrvRateAnalysis.getVariationPrice(data.brand, data.filters)
        .then(res => {
            if (res.status === 200) {
              this.data[this.activeTab] = res.data.table.rows
              this.$store.commit('LOADING', false)
            }
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
      },
      showModal(data, info){
        this.infoModal = info
        data.CHANNEL = this.$store.getters.hasChannel
        data.variation_type=this.activeTab
        SrvRateAnalysis.getModaldata(data)
        .then(res => {
            if (res.status === 200) {
              this.$store.commit('LOADING', false)
              this.dataModal = res.data
              this.dialog = true
            }
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
      },
  }

}
</script>
<style lang="scss" scoped>
  .block-tabs{
    max-width: 300px;
    .gp-tab{
      cursor: pointer;
      height: 40px;
      width: 193px;
      background-color: #fff;
      display: block;
      padding: 9px 0 0 7px;
      font-size: 14px;
      border-color: #D9DFE8;
      border-width: 1px 1px 0 1px;
      border-style: solid;
      border-radius: 5px 5px 0 0;
      &.tab-active{
        position: relative;
        &::before{
          content: "";
          background-color: #ffffff;
          width: 142px;
          height: 10px;
          position: absolute;
          bottom: -2px;
          left: 0;
        }
      }
    }
  }
.block-content-tab{
  background-color: #fff;
  border: 1px solid #D9DFE8;
  border-radius: 0 5px 5px 5px;
  width: 100%;

}
.modal-small-text{
  font-size: 12px;
}
.modal-big-text{
  font-size: 22px;
}
.border-in-modal{
  border: 1px solid #D9DFE8;
  border-radius: 10px;
}
.close-modal{
  position: absolute;
  right: 11px;
  top: 14px;
}
#tableVariationsModal{
  margin-top:10px;
  border-top: 10px solid #000;
  .header{
    &.row{
      margin:0;
      font-weight: 600;
      border-bottom: 2px solid #000;
    }
  }
  .container{
    padding: 0;
    .row{
      margin: 0;
    }
  }
}
</style>
