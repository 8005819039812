import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import routes from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios'

// Translations
import GetTextPlugin from 'vue-gettext'
import translations from '@/translations/translation.json'

//config
import appConfig from '@/config-app.js'
import Mixins from "@/plugins/mixins";

Vue.config.productionTip = false

let userLocale = navigator.languages ? navigator.languages[0] : (navigator.language || navigator.userLanguage)
userLocale = (userLocale.startsWith('es-')) ? 'es' : userLocale

// Translations config
Vue.use(GetTextPlugin, {
  availableLanguages: {
    es: 'Español',
    en: 'Inglés',
    pt: 'Portugues',
  },
  defaultLanguage: userLocale,
  languageVmMixin: {
    computed: {
      currentKebabCase: function () {
        return this.current.toLowerCase().replace('_', '-')
      }
    }
  },
  translations: translations,
  silent: true
})

Vue.use(appConfig)
Vue.use(VueRouter)
Vue.use(Mixins)

// Configuración del router
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(savedPosition)
        }, 2500)
      })
    } else {
      return { x: 0, y: 10 }
    }
  }
})

router.beforeEach((to, from, next) => {
  document.body.className = `body-${to.name}`
  // rutes auth
  if (to.meta.Auth && store.state.user.hasToken === '') {
    // verify token
    let poToken = null;
    if(window.localStorage.getItem('_token')){
      poToken = window.localStorage.getItem('_token');
    }else if(window.sessionStorage.getItem('_token')){
      poToken = window.sessionStorage.getItem('_token');
    }
    if (poToken) {
      next()
    } else {
      // Si no viene el token, mandamos al logueo.
      next({path: '/'})
    }
  }
  next()
})

// number filter
Vue.filter('formatNumber', (val, min=2, max=2) => {
  if (typeof(val) !== 'string') {
    if(val === null){
      return '-'
    }
    return Number(val).toLocaleString('es-ES', { minimumFractionDigits: min, maximumFractionDigits: max })
  }
  return val
})

new Vue({
  router,
  store,
  axios,
  vuetify,
  render: h => h(App)
}).$mount('#app')
