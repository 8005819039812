import { render, staticRenderFns } from "./ipj.vue?vue&type=template&id=353c5d7e&scoped=true&lang=pug&"
import script from "./ipj.vue?vue&type=script&lang=js&"
export * from "./ipj.vue?vue&type=script&lang=js&"
import style0 from "./ipj.vue?vue&type=style&index=0&id=353c5d7e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "353c5d7e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCol,VContainer,VImg,VSkeletonLoader})
