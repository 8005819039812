<template lang="pug">
  div.row.pt-1.pb-1.px-1(style="position: relative;")
    .header.row(style="position: fixed; z-index: 1; background-color: white; background-color: white; box-shadow: 0 0 0px 1px rgb(206 209 212 / 75%); width: calc(100% - 10.85em); z-index: 1;")
      v-col.border-col-rigth.pt-3.pb-0(cols="3")
        translate.d-block.mt-3 Plate
        //svg.sort-icon.inactive.cursor-pointer(xmlns="http://www.w3.org/2000/svg" width="12.828" height="12" viewBox="0 0 12.828 12" :class="{'activeSort': 0 === idSortCol, 'sortAsc': sortDesc !== false }" @click="sortCol(0)")
          g(id="download" transform="translate(11.414 11) rotate(180)")
            path(id="Trazado_4786" data-name="Trazado 4786" d="M0,0,5,5l5-5" transform="translate(0 5)" fill="none" stroke="#4a90e2" stroke-linecap="round" stroke-linejoin="round" stroke-width="2")
            line(id="Línea_1029" data-name="Línea 1029" y1="10" transform="translate(5)" fill="none" stroke="#4a90e2" stroke-linecap="round" stroke-linejoin="round" stroke-width="2")
      v-col.border-col-rigth.pt-3.pb-0(cols="2")
        translate.d-block.mt-3 Brand
      v-col.border-col-rigth.pt-3.pb-0(cols="2")
        translate.d-block.mt-3 Province
      v-col.border-col-rigth.pt-3.pb-0
        translate.d-block.mt-3 Channel
      v-col.border-col-rigth.pt-3.pb-0.text-center.p-relative
        .boxOrder
          span(@click="cleanOrder()")
            v-icon mdi-close
          span(@click="setOrderData(8,'desc')")
            v-icon(:class="{'arrowActive': (ord === 'desc' && fieldOrder === 8)}") mdi-arrow-up
          span(@click="setOrderData(8,'asc')")
            v-icon(:class="{'arrowActive': (ord === 'asc' && fieldOrder === 8)}")  mdi-arrow-down
        translate.d-block.mt-3 Price yesterday
      v-col.border-col-rigth.pt-3.pb-0.text-center.p-relative
        .boxOrder
          span(@click="cleanOrder()")
            v-icon mdi-close
          span(@click="setOrderData(9,'desc')")
            v-icon(:class="{'arrowActive': (ord === 'desc' && fieldOrder === 9)}") mdi-arrow-up
          span(@click="setOrderData(9,'asc')")
            v-icon(:class="{'arrowActive': (ord === 'asc' && fieldOrder === 9)}")  mdi-arrow-down
        translate.d-block.mt-3  Price today
      v-col.border-col-rigth.pt-3.pb-0.text-center.p-relative
        .boxOrder
          span(@click="cleanOrder()")
            v-icon mdi-close
          span(@click="setOrderData(10,'desc')")
            v-icon(:class="{'arrowActive': (ord === 'desc' && fieldOrder === 10)}") mdi-arrow-up
          span(@click="setOrderData(10,'asc')")
            v-icon(:class="{'arrowActive': (ord === 'asc' && fieldOrder === 10)}")  mdi-arrow-down
        translate.d-block.mt-3  Variation
      v-col.pt-3.pb-0.text-center.p-relative
        .boxOrder
          span(@click="cleanOrder()")
            v-icon mdi-close
          span(@click="setOrderData(11,'desc')")
            v-icon(:class="{'arrowActive': (ord === 'desc' && fieldOrder === 11)}") mdi-arrow-up
          span(@click="setOrderData(11,'asc')")
            v-icon(:class="{'arrowActive': (ord === 'asc' && fieldOrder === 11)}")  mdi-arrow-down
        translate.d-block.mt-3  Deviation typical
    .content-row.row.border-b-gray(v-if="dataSorted" v-for="(row, i) in dataSorted"  :class="{'bg-ligth-gray': i % 2 !== 0, 'margin-top-49': i === 0}")
      v-col.pt-5(v-for="(item, index) in row" v-if="index > 3" :class="{'col-3':index === 4,'col-2':index === 5 || index === 6, 'text-center': index >= 8, 'border-col-rigth': index < 11}")
        span.cursor-pointer.terciary--text.font-weight-med(v-if="index < 5" @click="showModal({MENU_ITEM_ID: row[0], BRAND_ID: row[1], PROVINCE_ID: row[2], DELIVERY_PLATFORM_ID: row[3]},{plate: row[4], brand: row[5], province: row[6], aggregator: getAggregatorIsEmpty(row[7]), yesterday_price: row[8], today_price: row[9], variation_pct: row[10], variation: row[11]} )") {{item}}
        span(v-if="index >= 5 && index < 7") {{item | formatNumber(2,2)}}
        span(v-if="index == 7") {{ getAggregatorIsEmpty(item) }}
        span(v-if="index >= 8 && index < 10") {{item | formatNumber(2,2)}} €
        span(v-if="index == 10" :class="{'color-red': item < 0,'color-green': item > 0 }") {{item | formatNumber(0,1)}} %
        span(v-if="index == 11") {{item | formatNumber(0,2)}} %
</template>
<script>
import _orderBy from 'lodash/orderBy'

export default {
  props: ["data","sortDefault"],
  data() {
    return {
      /*idSortCol: '',
      sortDesc: true,*/
      fieldOrder: 10,
      ord: this.sortDefault
    }
  },
  computed: {
    /*rows() {
      let rows = [...this.data].sort((a, b) => {
        return b[this.idSortCol] - a[this.idSortCol]
      })
      if(!this.sortDesc){
        rows = rows.reverse()
      }
      return rows
    },*/
    dataSorted () {
      return _orderBy(this.data, this.fieldOrder, this.ord)
    },
  },
  methods:{
    cleanOrder () {
      this.fieldOrder = 10
      this.ord = this.sortDefault
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    setOrderData (campo, ord) { 
      this.fieldOrder = campo
      this.ord = ord
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    showModal(data, info){
        this.$emit("showModal", data, info)
    },
    getAggregatorIsEmpty (item) {
      let aggregator = item
      if(!aggregator) {
        aggregator = this.$store.getters.hasChannel.name
      }
      return aggregator
    },
    sortCol(id){
      if(id === this.idSortCol){
        this.sortDesc = !this.sortDesc
      }
      this.idSortCol = id
    }
  }
}
</script>
<style lang="scss" scoped>
.boxOrder{
  position: absolute;
  top: 0;
  left:0;
  width: 100%;
  text-align: center;
  span{
    display: inline-block;
    margin: 0 5px;    
    .v-icon{
      font-size: 16px;
      cursor:pointer;
      &.arrowActive{
        color:#498FE1;
      }
    }
  }
}
  .header{
    width: 100%;
    border-bottom: 1px solid #4A4A4A;
    span{
      font-size: 14px;
      font-weight: 600;
    }
  }
  .content-row{
    width: 100%;
    font-size: 14px;
  }

  .margin-top-49 {
    margin-top: 49px;
  }
</style>
