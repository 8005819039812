<template lang="pug">
  v-container.login.pa-0.heigth-100(fluid)
    v-row.align-center
      v-col.d-none.d-md-flex.heigth-100(cols="5")
        v-img.heigth-100(:src="img.background_login")
      v-col.pt-10(cols="12" md="7")
        v-row.flex-column.align-center.px-10.text-center
          v-img(:src="img.logo" max-width="335")
          p.primary--text.subtitle.mt-5 #[translate Management of restaurant prices and profitability]
          .blockError
            p.pt-1(v-if="errorMsg") {{errorMsg}}
          form#form_login
            div.margin-bottom-32
              v-text-field.field-login(v-model="username" :placeholder="placeholder.username" @focus="resetError()")
            div.margin-bottom-32
              v-text-field.field-login(v-model="password" :placeholder="placeholder.pwd" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword" @focus="resetError()")
            v-row
              v-col.text-left(md="5")
                v-checkbox.remember.mt-0(v-model="RememberCheck" :label="placeholder.check" off-icon="mdi-checkbox-blank-outline" on-icon="mdi-checkbox-intermediate")
              v-col.text-right(md="7")
                //a.forgot-link.secondary--text.text-decoration-none(href="/restore") #[translate Forgot Password]
            div.text-center
              v-btn.mb-0(color="primary" type="button" width="185" height="50" @click="send" @keyup.enter="send" :disabled="!username || !password") #[translate Login]
</template>

<script>
import SrvLogin from '@/services/login.js'
import SrvDashboard from '@/services/dashboard.js'
export default {
  name: 'Home',
  data() {
    return {
      dialog: false,
      img: {
        background_login: require('@/assets/img/auth/fondo.png'),
        logo: require('@/assets/img/auth/logo.png'),
      },
      username: null,
      password: null,
      errorMsg: false,
      showPassword: false,
      placeholder: {
        'username': this.$gettext('Username'),
        'pwd': this.$gettext('Password'),
        'check': this.$gettext('Remember me'),
      },
      RememberCheck: false,
    }
  },
  created() {
    if(window.localStorage.getItem("_token") || window.sessionStorage.getItem("_token")) {      
      // INTENTAR HACER LOGIN
      this.autoLogin()
    }
    if(this.$route.query.token) {
      this.token = this.$route.query.token
      window.localStorage.setItem('_token', this.token)
      this.autoLogin()
    } 
  },
  components: {
  },
  methods: {
    resetError () {
      this.errorMsg = false
    },
    send () {
      this.resetError()
      // let RegEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,4})+$/
      // if (email === null || email === '' || !email.match(RegEx)) {
      //   this.errorMsg = true
      //   this.errorEmail = true
      // }

      SrvLogin.getToken({username: this.username, password: this.password})
        .then(async res => {          
          if(this.RememberCheck){
            window.localStorage.setItem('_token', res.data.token)
            window.localStorage.setItem('_user', this.username)
          }else{
            window.sessionStorage.setItem('_token', res.data.token)
            window.sessionStorage.setItem('_user', this.username)
          }
          this.$store.commit('IS_LOGIN', res.data.token)
          
          this.autoLogin()
        }, (error) => {
          this.errorMsg = error.data.non_field_errors[0]

        })      
    },
    async autoLogin () {   
      let countries = await SrvDashboard.getCountries()
      const result = countries.data.filter(c => c.code == window.localStorage.getItem('_country_selected'))
      if(result.length > 0){
        window.localStorage.setItem('_country_selected',result[0].code)
      } else {
        window.localStorage.setItem('_country_selected',countries.data[0].code)
      }
      window.localStorage.setItem('_countries',JSON.stringify(countries.data))  
      this.$router.push({name: 'rateAnalysis'})
    },
  }
}
</script>
<style lang="scss">
  .login{
    .subtitle{
      font-family: 'Playfair Display';
      font-size: 18px;
    }
    #form_login{
      width:100%;
      max-width: 500px;
      .field-login{
        .v-label{
          color: #000;
          font-size: 15px;
        }
        input{
          font-size: 15px;
          &::placeholder{
            color:#000;
          }
        }
      }
      .remember{
        .v-label{
          color: var(--v-secondary-base);
          font-size: 14px;
        }
      }
      .forgot-link{
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
//   .login{
//     .invitation{
//       color:#0D6AFF;
//       cursor: pointer;
//       margin-top: 12px;
//     }
//     .copy{
//       font-size: 10px;
//     }
//   }
//   .container.dialog-container{
//     max-width: 400px;
//     h2{
//       font-family: 'Roboto Slab';
//       font-size: 22px;
//       color: #003A70;
//       font-weight: bold;
//     }
//     .field-modal{
//       .v-label{
//         color: var(--v-disposable_gray-base);
//         font-size: 15px;
//       }
//       input{
//         font-size: 15px;
//       }
//     }
//     .accepting{
//       font-size: 12px;
//       color: #43425D;
//     }
//   }
.blockError{
  height: 35px;
  p{
    color:#EC4444
  }
}
</style>