<template lang="pug">
  router-view
    // fix translate
    span.d-none #[translate Promotions Analysis]
</template>
<script>

export default {
  name: 'promotionsAnalysis',
  data() {
    return {
      titleFilter: this.$gettext('Promotions Analysis')

    }
  },
  created(){
    this.$store.dispatch('loadMixPanelEvent')
    if(!this.$store.getters.hasToken) {
      this.$store.dispatch('loadToken')
    }
    this.$store.commit('TITLE_HEADER', this.titleFilter)
    this.$store.commit('HAS_MENU', 'promotions')
  },
  computed: {
  },
  methods: {

  },
  mounted () {
    let elementBody = document.querySelector('.gastroprice-content')
    elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight) + 'px;')
    this.$nextTick(() =>{
      window.addEventListener('resize', function () {
        let elementBody = document.querySelector('.gastroprice-content')
        elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight) + 'px;')
      })
    })
  }
}
</script>
<style lang="scss" scoped>

</style>