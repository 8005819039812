import Vue from 'vue'
import Vuex from 'vuex'

// Stores
import User from '@/store/user'
import filters from '@/store/filters'
import common from '@/store/common'

Vue.use(Vuex)

const stores = new Vuex.Store({
  modules: {
    user: User,
    filters: filters,
    common: common
  }
})

export default stores
