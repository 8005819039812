// Importamos mutaciones
import * as types from '@/store/mutation-types'


const user = {
  state: {
    hasToken: '', // Si no esta el token en False y si lo esta es true
    user: null,   
  },
  actions: {
    loadToken ({commit}) {
      let token = '';
      if(window.localStorage.getItem('_token')){
        token = window.localStorage.getItem('_token');
      }else if(window.sessionStorage.getItem('_token')){
        token = window.sessionStorage.getItem('_token');
      }
      commit(types.IS_LOGIN, token)
    },
    loadMixPanelEvent () {
      if (document.getElementById('scriptMixPanelEvent')){
        const scriptElem = document.getElementById('scriptMixPanelEvent');
        scriptElem.remove() 
      }
      const scriptLine = document.createElement('script')
      scriptLine.id = 'scriptMixPanelEvent'
      scriptLine.text = `
      /* Track Navigation Event */
      mixpanel.track('Navigation Event'); 
      `
      document.head.appendChild(scriptLine)
    },
  },
  mutations: {
    [types.IS_LOGIN] (state, token ) {
      state.hasToken = token
    },
  
  },
  getters: {
    hasToken: state => {
      return state.hasToken
    }
  }
}

export default user
