<template lang="pug">
div  
  gp-filter-top#filtersFixed(@getData="getIpd")
  v-container.gastroprice-content.d-flex.flex-column(fluid)
    .block-content.row
      v-container.table-data.ma-5.pa-0(v-if="isLoading" fluid)
        v-skeleton-loader(type="table")
      v-container.table-data.ma-5.pa-0(v-if="!isLoading" fluid)
        .table-title.pa-4
          p.mb-0.pt-3 #[translate IPD - Price Dispersion Index]
        .table-content
          .header.d-flex(v-if="ipdData.table")
            v-col.col-filter-family.first-col.filter-top
              v-select.pt-0(v-model="familySelect" :items="familiesCustom" item-text="name" return-object :placeholder="placeholder.family" no-data-text="no hay familias" color="terciary" item-color="#ECF2F9"  @change="changeFamily" :menu-props="{offsetY: true }" clearable)
                template(slot="append")
                  v-icon(color="terciary") mdi-chevron-down
                template(v-slot:selection="{ item, index }")
                    span(v-if="item").terciary--text.text-uppercase.text-elipsis {{ item.name }}
                template(v-slot:item="{item }")
                    span.option-filter(v-if="item" :class="{'selected' : familySelect.name === item.name}") {{ item.name }}
            v-col.px-0(v-if="index > 0 && index < ipdData.table.header.length - 2" v-for="(item, index) in ipdData.table.header" :class="{'myRestaurant': index == 1 || index == ipdData.table.header.length - 2, 'first-col': index === 0}")
              .block-img(v-if="item" :style="{ backgroundImage: 'url(' + getImg(item.logo) + ')' }")
            v-col.px-0.pt-1.col-average.d-flex.align-end.justify-center
              p.mb-1 #[translate Average]
            v-col.px-0.pt-1.col-variation.d-flex.align-end.justify-center
              p.mb-1 #[translate Variation vs Average]
          .content-item.d-flex.align-stretch(v-if="ipdDataRows" v-for="(ipd, index) in ipdDataRows" :class="{'mean_price' : index === 0, 'dispersion': index === 4 || index === 5, 'more-info': index > 5}")
            v-col.d-flex.align-center.title-row.first-col
              p.mb-0( :class="{'font-weight-medium' : ipd[0] === 'N_ITEMS' }") {{titleRow[ipd[0]]}}
            v-col.d-flex.align-center(v-if="i > 0" v-for="(item, i) in ipd" :class="{'justify-center': i > 0, 'myRestaurant': i == 1 , 'col-average': i == ipd.length - 2, 'col-deviation': i == ipd.length - 1}")
              p.mb-0(v-if="i !== ipd.length - 1")
                span(v-if="index > 5") {{item | formatNumber(0,0)}}
                span(v-if="index <= 5") {{item | formatNumber}}
                span(v-if="item !== null && index < 6 && index !== 4 && i > 0 && i < ipd.length - 1") &nbsp; €
                span(v-if="item !== null && index === 4" ) &nbsp; %
              p.mb-0(v-if="i === ipd.length - 1"  ) {{item | formatNumber}}
                span(v-if="item !== null")  &nbsp; %
    .block-explication.row(v-if="1!==1")
      v-col.d-flex.justify-end.col-img
        .block-img
          v-img(:src="img.ico_data_tooltip" width="91" height="73")
      v-col.d-flex.flex-wrap
        v-col.d-flex.badge-item.my-2(cols="11")
          v-col(cols="12")
            p.mb-0 #[translate RECOMMENDATIONS]
            p.mb-0.font-weight-medium #[translate Nº Items:]
            p.mb-0.font-weight-medium #[translate Pryce Entry:] 
            p.mb-0.font-weight-medium Tenemos 50% menos de productos en Price entry
            p.mb-0.font-weight-medium #[translate Premium Price:]
            p.mb-0.font-weight-medium #[translate Total Dispersion:]
</template>
<script>
import GpFilterTop from '@/components/dashboard/filter-top.vue'
import SrvDashboard from '@/services/dashboard.js'

export default {
  components: {GpFilterTop},
  name: 'ipd',
  data() {
    return {
      firstGet: true,
      dataQuery: [],
      ipdData: [],
      ipdDataRows: [],
      lineExtraInfo: '',
      families: [],
      familySelect: '',
      placeholder: {
        family: this.$gettext('FAMILY')
      },
      img: {
        ico_data_tooltip : require('@/assets/img/ico_data_tooltip.png'),
        no_img: require('@/assets/img/logos/restaurant_icon.png')
      },
      titleRow:{
        'MEAN_PRICE': this.$gettext('Half price'),
        'ENTRY_PRICE': this.$gettext('Entry Price'),
        'ROTATION_PRICE': this.$gettext('Rotation Price'),
        'IPJ': this.$gettext('Premium Price'),
        'PREMIUM_PRICE': this.$gettext('Premium Price'),
        'DISPERSION_PCT': this.$gettext('Dispersion %'),
        'DISPERSION_EUR': this.$gettext('Dispersion €'),
        'N_ITEMS': this.$gettext('Nº Ítems'),
        'N_ITEMS_ENTRY': this.$gettext('Entry'),
        'N_ITEMS_ROTATION': this.$gettext('Rotation'),
        'N_ITEMS_PREMIUM': this.$gettext('Premium'),
      },
      titleFilter: this.$gettext('Price Dispersion Index'),
    }
  },
  created(){
    this.$store.dispatch('loadMixPanelEvent')
    if(!this.$store.getters.hasToken) {
      this.$store.dispatch('loadToken')
    }
    this.getFamilies(this.$store.getters.hasBrand.id)
    this.$store.commit('TITLE_HEADER', this.titleFilter)
    this.$store.commit('HAS_MENU', '')
  },
  computed: {
    isLoading(){
      return this.$store.getters.isLoading
    },
    familiesCustom () {
      return this.$store.getters.hasFamilies
    }
  },
  methods: {
    getIpd(data){
      this.$store.commit('LOADING', true)
      if(this.firstGet){
        this.getFirstIpd(data)
      } else {
        SrvDashboard.getDashData('ipd', data.brand, data.filters)
        .then(res => {
            if (res.status === 200) {
              this.dataQuery = data
              this.ipdData = res.data
              this.ipdDataRows = res.data.table.rows
              this.lineExtraInfo = this.ipdDataRows.length - 2
              this.$store.commit('LOADING', false)
            } 
          }, () => {
          })
      }
    },
    getFirstIpd(data){
      this.firstGet = false
      // data.filters.family = '2'
      SrvDashboard.getDashData('ipd', data.brand, data.filters)
      .then(res => {
          if (res.status === 200) {
            this.dataQuery = data
            this.ipdData = res.data
            this.ipdDataRows = res.data.table.rows
            this.lineExtraInfo = this.ipdDataRows.length - 2
            this.$store.commit('LOADING', false)
          } 
        }, () => {
        })
    },
    getFamilies(brand){
      SrvDashboard.getFamilies(brand)
      .then(res => {
          if (res.status === 200) {
            this.families = res.data
            this.families.forEach(element => {
              if(element.id === 2){
                this.familySelect = element
              }
            });
          } 
        }, () => {
        })
    },
    getImg(img){
      return img ? img : this.img.no_img
    },
    changeFamily(){
      if(this.familySelect === null) {
        this.familySelect = ''
      }
      this.dataQuery.filters.family = this.familySelect.id
      this.getIpd(this.dataQuery)
    },
  },
  mounted () {
    let elementBody = document.querySelector('.gastroprice-content')
    elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight) + 'px;')
    this.$nextTick(() =>{
      window.addEventListener('resize', function () {
        let elementBody = document.querySelector('.gastroprice-content')
        elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight) + 'px;')
      })
    })
  }
}
</script>
<style lang="scss" scoped>
  .gastroprice-content{
    font-size: 14px;
    .first-col{
      flex: 0 0 20%;
      max-width: 20%;
    }
    .header{
      font-size: 12px;
      text-align: center;
      color:#5B5B5B;
    }
    .content-item{
      border-bottom: 1px solid #D9DFE8;
      .myRestaurant{
        font-weight: 500;
      }
      .col-average{
        font-weight: normal!important;
      }
      &.mean_price{
        .first-col{
          font-weight: 500;
        }
        .col-average{
          font-weight: 500!important;
        }
      }
      &.dispersion{
        background-color: #4a91e207;
        font-size: 15px;
        .col{
          font-weight: 700;
          &.first-col{
            font-weight: 500;
            justify-content: flex-end;
          }
        }
        .col-average{
          font-weight: 500!important;
        }
      }
      &.more-info{
        height: 35px;
        .first-col{
          justify-content: flex-end;
        }
      }
    }
  }

</style>