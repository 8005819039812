<template lang="pug">
div
  gp-filter-top#filtersFixed(@getData="getIpb")
  v-container.gastroprice-content.d-flex.flex-column(fluid)    
    .block-content.row
      v-container.table-data.ma-5.pa-0(v-if="isLoading" fluid)
        v-skeleton-loader(type="table")
      v-container.table-data.ma-5.pa-0(v-if="!isLoading" fluid)
        .table-title.pa-4
          p.mb-0.pt-3 #[translate IPB - Basic Prices Index]
        .table-content
          .header.d-flex(v-if="ipbData.table")
            v-col(v-for="(item, index) in ipbData.table.header" :class="{'myRestaurant': index == 1 || index == ipbData.table.header.length - 2, 'first-col': index === 0}")
              .block-img(v-if="item" :style="{ backgroundImage: 'url(' + getImg(item.logo) + ')' }")
          .subheader.d-flex(v-if="ipbData.table")
            v-col.pa-0.pt-1.first-col
            v-col.pa-0.pt-1(v-if="index > 0 && index < ipbData.table.header.length - 2" :class="{'myRestaurant': index == 1}" v-for="(item, index) in ipbData.table.header")
              p.mb-1 #[translate Average PVP]
            v-col.pa-0.pt-1.col-average
              p.mb-1 #[translate Average (M)]
            v-col.pa-0.pt-1
              p.mb-1 #[translate Variation]
          .content-item.d-flex.align-stretch(v-if="ipbDataRows && index + 2 < ipbDataRows.length" v-for="(ipb, index) in ipbDataRows")
            v-col.d-flex.align-center(v-for="(item, index) in ipb" :class="{'justify-center': index > 0, 'title-row': index == 0, 'first-col': index == 0 , 'myRestaurant': index == 1 , 'col-average': index == ipb.length - 2, 'col-deviation': index == ipb.length - 1}")
              p.mb-0(v-if="index !== ipb.length - 1") {{item | formatNumber}}
                span(v-if="item && index > 1 && index < ipb.length - 1") &nbsp; €
              p.mb-0(v-if="index === ipb.length - 1" :class="getArrow(item)") {{item | formatNumber}}
                span(v-if="item !== null") &nbsp; %
          .content-total.d-flex.align-stretch
            v-col.d-flex.justify-center.align-center(v-for="(item, index) in ipbDataRows[lineExtraInfo]" :class="{'title-row first-col': index === 0, 'myRestaurant': index == 1, 'col-average': index == ipbData.table.header.length - 2, 'col-deviation': index == ipbData.table.header.length - 1}")
              p.mb-0(v-if="index !== ipbDataRows[lineExtraInfo].length - 1") {{item | formatNumber}}
                span(v-if="index > 0 && index < ipbDataRows[lineExtraInfo].length - 1") &nbsp; €
              p.mb-0(v-if="index === ipbDataRows[lineExtraInfo].length - 1" :class="getArrow(item)") {{item | formatNumber}}
                span &nbsp; %
          .content-ipb.d-flex.align-stretch
            v-col.d-flex.justify-center.align-center( v-for="(item, index) in ipbDataRows[lineExtraInfo + 1]" :class="{'title-row first-col': index === 0, 'myRestaurant': index == 1, 'col-average': index == ipbData.table.header.length - 2, 'col-deviation': index == ipbData.table.header.length - 1}")
              p.mb-0(v-if="index !== ipbDataRows[lineExtraInfo + 1].length - 1") {{item | formatNumber}}
                span(v-if="index > 0 && index < ipbDataRows[lineExtraInfo + 1].length - 1") &nbsp; €
              p.mb-0(v-if="index === ipbDataRows[lineExtraInfo + 1].length - 1" :class="getArrow(item)") {{item | formatNumber}}
                span &nbsp; %
</template>
<script>
import GpFilterTop from '@/components/dashboard/filter-top.vue'
import SrvDashboard from '@/services/dashboard.js'

export default {
  components: {GpFilterTop},
  name: 'ipb',
  data() {
    return {
      ipbData: [],
      ipbDataRows: [],
      lineExtraInfo: '',
      img:{
        no_img: require('@/assets/img/logos/restaurant_icon.png')
      },
      titleFilter: this.$gettext('Indicator of the price differential in the most common products'),
    }
  },
  created(){
    this.$store.dispatch('loadMixPanelEvent')
    if(!this.$store.getters.hasToken) {
      this.$store.dispatch('loadToken')
    }
    this.$store.commit('TITLE_HEADER', this.titleFilter)
    this.$store.commit('HAS_MENU', '')
  },
  computed: {
    isLoading(){
      return this.$store.getters.isLoading
    }
  },
  methods: {
    getIpb(data){
      this.$store.commit('LOADING', true)
      SrvDashboard.getDashData('ipb', data.brand, data.filters)
      .then(res => {
          if (res.status === 200) {
            this.ipbData = res.data
            this.ipbDataRows = res.data.table.rows
            this.lineExtraInfo = this.ipbDataRows.length - 2
            this.$store.commit('LOADING', false)
          } 
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    getImg(img){
      return img ? img : this.img.no_img
    },
    getArrow(data){
      if( data > 0){
        return 'arrow-up'
      } else if(data === 0){
        return 'square'        
      } else if(data < 0){
        return 'arrow-down'
      } 
      return ''
    }
  },
  mounted () {
    let elementBody = document.querySelector('.gastroprice-content')
    elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight) + 'px;')
    this.$nextTick(() =>{
      window.addEventListener('resize', function () {
        let elementBody = document.querySelector('.gastroprice-content')
        elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight) + 'px;')
      })
    })
  }
}
</script>
<style lang="scss" scoped>
  .gastroprice-content{
    .table-content{
      font-size: 14px;
      .first-col{
        flex: 0 0 15%;
        max-width: 15%;
      }
    }
    .col-deviation{
      position: relative;
      p{
        &.arrow-up{
          &:before{
            content: "\a";
            position: absolute;
            left: 20px;
            top: 40%;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 10px solid #E8523F;
          }
        }
        &.arrow-down{
          &:before{
            content: "\a";
            position: absolute;
            left: 20px;
            top: 40%;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 10px solid #43dd4b;
          }
        }
        &.square{
          &:before{
            content: "\a";
            position: absolute;
            left: 20px;
            top: 40%;
            width: 10px;
            height: 10px;
            background-color: #F5A623;
          }
        }
      }
    }
    .content-item{
      border-bottom: 1px solid #D9DFE8;
    }
    .content-total{
      font-size: 15px;
      border-top: 1px solid #9B9B9B;
      border-bottom: 1px solid #D9DFE8;
      height: 50px;
      .title-row{
        position:relative;
        p{
          position: absolute;
          right: 10px;
          white-space: nowrap;
        }
      }
    }
    .content-ipb{
      font-size: 15px;
      border-bottom: 1px solid #D9DFE8;
      background-color: #4a91e207;
      height: 70px;
      font-weight: 500;
      .title-row{
        position:relative;
        p{
          position: absolute;
          right: 10px;
          white-space: nowrap;
        }
      }
      .myRestaurant{
        p{
          font-weight: 700;
        }
      }
      .col-average{
        p{
           font-weight: 700;
        }
      }
      .col-deviation{
        p{
           font-weight: 700;
        }
      }
    }
  }

</style>