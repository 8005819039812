import Home from '../views/Home.vue'
// import forgot from '../views/forgot.vue'
// import reset from '../views/reset.vue'
import ips from '../views/dashboard/ips.vue'
import ipb from '../views/dashboard/ipb.vue'
import ipj from '../views/dashboard/ipj.vue'
import ipd from '../views/dashboard/ipd.vue'
// import icd from '../views/dashboard/icd.vue'
// import rde from '../views/dashboard/rde.vue'
import rateAnalysis from '../views/dashboard/rateAnalysis/rateAnalysis.vue'
import priceVariations from '../views/dashboard/rateAnalysis/priceVariations.vue'
import rateComparator from '../views/dashboard/rateAnalysis/rateComparator.vue'
import aggregatorsRank from '../views/dashboard/rateAnalysis/aggregatorsRank.vue'
import provincesRank from '../views/dashboard/rateAnalysis/provincesRank.vue'

import promotionsAnalysis from '../views/dashboard/promotionsAnalysis/promotionsAnalysis.vue'
import promoComparator from '../views/dashboard/promotionsAnalysis/promoComparator.vue'
import promoNews from '../views/dashboard/promotionsAnalysis/promoNews.vue'
import promoFinished from '../views/dashboard/promotionsAnalysis/promoFinished.vue'

// NOT FOUNT PAGE
import NotFound from '../views/404.vue'

import RestorePassword from '../views/Restore.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {Auth: false}
  },
  {
    path: '/ips',
    name: 'ips',
    component: ips,
    meta: {Auth: true}
  },
  {
    path: '/ipb',
    name: 'ipb',
    component: ipb,
    meta: {Auth: true}
  },
  {
    path: '/ipj',
    name: 'ipj',
    component: ipj,
    meta: {Auth: true}
  },
  {
    path: '/ipd',
    name: 'ipd',
    component: ipd,
    meta: {Auth: true}
  },
  /*{
    path: '/icd',
    name: 'icd',
    component: icd,
    meta: {Auth: true}
  },*/
  {
    path: '/rate-analysis',
    name: 'rateAnalysis',
    component: rateAnalysis,
    redirect: '/rate-analysis/rate-comparator',
    meta: {Auth: true},
    children: [ 
      {
        path: 'price-variations',
        name: 'priceVariations',
        component: priceVariations,
        meta: {Auth: true}
      },
      {
        path: 'rate-comparator',
        name: 'rateComparator',
        component: rateComparator,
        meta: {Auth: true}
      },
      {
        path: 'aggregators-rank',
        name: 'aggregatorsRank',
        component: aggregatorsRank,
        meta: {Auth: true}
      },
      {
        path: 'provinces-rank',
        name: 'provincesRank',
        component: provincesRank,
        meta: {Auth: true}
      },
    ]
  },
  {
    path: '/promotions-analysis',
    name: 'promotionsAnalysis',
    component: promotionsAnalysis,
    redirect: '/promotions-analysis/promo-comparator',
    meta: {Auth: true},
    children: [      
      {
        path: 'promo-comparator',
        name: 'promoComparator',
        component: promoComparator,
        meta: {Auth: true}
      },
      {
        path: 'promo-news',
        name: 'promoNews',
        component: promoNews,
        meta: {Auth: true}
      },
      {
        path: 'promo-finished',
        name: 'promoFinished',
        component: promoFinished,
        meta: {Auth: true}
      }
    ]
  },
  { 
    path: '*',
    name: '404',
    component: NotFound,
    meta: {Auth: false}
  },
  {
    path: '/restore',
    name: 'Restore',
    component: RestorePassword,
    props: false,
    meta: {Auth: false},
  },
  // {
  //   path: '/reset-password',
  //   name: 'reset',
  //   component: reset,
  //   meta: {Auth: false}
  // },
  // {
  //   path: '/configurator',
  //   name: 'configurator',
  //   component: Configurator,
  //   meta: {Auth: true},
  // }
]

export default routes
