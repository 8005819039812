<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="6.401" height="10.059" viewBox="0 0 6.401 10.059">
    <g id="Group_10232" data-name="Group 10232" transform="translate(2.797 4.395)">
      <g id="Group_8574" data-name="Group 8574" transform="translate(-2.797 -4.395)">
        <path id="Arrow_Copy" data-name="Arrow Copy" d="M1.314,9.854,6.245,5.371a.452.452,0,0,0,0-.683L1.314.2A.826.826,0,0,0,.225.2a.656.656,0,0,0,0,.989L4.444,5.03.225,8.865a.657.657,0,0,0,0,.99.826.826,0,0,0,1.089,0" transform="translate(0 0)" fill="#6fa7e8"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "svgChevronToRight"
}
</script>