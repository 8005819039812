<template lang="pug">
  v-dialog( v-model="visible" width="930" persistent)

    .gp_guided-help__modal

      img.gp_guided-help__close-button(:src="img.close" @click="$emit('update:visible', false)")

      .gp_guided-help__title {{ title }}

      .gp_guided-help__body

        transition-group(tag="div" name="bot" mode="out-in")
          .gp_guided-help__body--svg-bot(:key="currentStep")
            component(:is="bot")

        .gp_guided-help__body--svg-go-previous(v-if="currentStep > 1" @mousedown="moveStep(currentStep - 1)")
          svg-previous-button

        .gp_guided-help__body--svg-go-next(v-if="currentStep < steps.length" @mousedown="moveStep(currentStep + 1)")
          svg-next-button

        .gp_guided-help__body--card-wrapper(:style="{ width: `${cardWidth}px` }")
          .gp_guided-help__body--card-scroll(:style="{transform: `translateX(${translateX}px)`}")
            .gp_guided-help__body--card(v-for="(step, i) in localSteps" :key="i" :card="i")
              div.card-wrapper(:style="{ width: `${cardWidth}px`, height: `100%`,  display: `flex` }")
                component(:is="cards[i]")

      .gp_guided-help__footer
        .gp_guided-help__stepper-wrapper
          .gp_guided-help__stepper-progress(:style="progress")
          .gp_guided-help__stepper-point(v-for="(step, i) in localSteps" @mousedown="moveStep(step.number)" :step="step.number" :key="i" :class="{ done: step.number < currentStep, current: step.number === currentStep }")
</template>

<script>
import SvgPreviousButton from "../../assets/img/svg/svgPreviousButton";
import SvgNextButton from "../../assets/img/svg/svgNextButton";
export default {
  name: "guidedHelp",
  components: {SvgNextButton, SvgPreviousButton},
  props: {
    visible: Boolean,
    title: String,
    steps: Array
  },
  data(){
    return {
      img:{
        close: require('@/assets/img/plus.png')
      },
      localSteps: [],
      pBarSize: '',
      currentStep: 1,
      events: false,
      cardWidth: 0,
      cards: []
    }
  },
  mounted(){
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyNavigation);
  },
  watch: {
    async visible(value){

      const wrapper = document.querySelector('.gp_guided-help__body--card-wrapper');
      if(wrapper){
        wrapper.style.opacity = 0;
      }

      this.currentStep = 1;

      this.cards = [];
      await this.loadCards();

      this.$nextTick(() => {
        if(value){
          const wrapper = document.querySelector('.gp_guided-help__body--card-wrapper');
          wrapper.style.opacity = 0;

          this.localSteps = this.steps.map((s, i) => ({ number: i+1, selected: false, ...s}) );

          this.localSteps[0].selected = true;

          setTimeout(() => {
            this.cardWidth = this.getCardWidth();
            wrapper.style.maxWidth = `${this.cardWidth}px`;
            wrapper.style.opacity = 1;
            setTimeout(() => {
              const DOMlist = document.querySelectorAll("[card]");
              [].forEach.call(DOMlist, (DOMel) => {
                if (!DOMel.keepAlive) {
                  DOMel.style.width =`${this.cardWidth}px`;
                }
              });
              setTimeout(() => {
                const DOMlist = document.querySelectorAll(".card-wrapper");
                [].forEach.call(DOMlist, (DOMel) => {
                  if (!DOMel.keepAlive) {
                    DOMel.style.width =`100%`;
                  }
                });
              }, 300)
            }, 300)
          }, 300)
        }
      })

      if(!this.events){
        window.addEventListener('keydown', this.keyNavigation);
        this.events = true;
      }
    }
  },
  computed: {
    progress() {
      if (this.currentStep > this.steps.length) {
        return `width: 100%`;
      }
      const first = document.querySelector('[step="1"]');
      const current = document.querySelector(`[step="${this.currentStep}"]`);
      if (first && current) {
        const delta = current.getBoundingClientRect().right - first.getBoundingClientRect().right;
        return `width: ${delta}px;`;
      } else {
        return `width: 0%`;
      }
    },
    translateX(){
      return -((this.currentStep - 1) * this.cardWidth);
    },
    bot() {
      const path = this.steps[this.currentStep - 1].bot;
      return () => ({
        component: import(`@/${path}`)
      })
    }
  },
  methods: {
    moveStep(stepNumber) {
      this.currentStep = stepNumber;
      if(this.currentStep > stepNumber){
        this.cardAnimation = 'card-prev'
      }
      if(this.currentStep < stepNumber){
        this.cardAnimation = 'card-next'
      }
    },
    getCardWidth(){
      const first = document.querySelector('[card="0"]');
      return first ? Math.round(first.getBoundingClientRect().width) : 0;
    },
    loadCards() {
      this.steps.forEach((item)=> {
        const loadedCard = () => ({
          component: import(`@/${item.card}`)
        })
        this.cards.push(loadedCard);
      })
    },
    keyNavigation(event){
      if (event.key === "ArrowRight" && this.currentStep < this.steps.length) {
        this.moveStep(this.currentStep + 1);
      }

      if (event.key === "ArrowLeft" && this.currentStep > 1) {
        this.moveStep(this.currentStep - 1);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.gp_guided-help__modal {
  box-sizing: border-box;
  position: relative;
  padding: 3.5em 3.5em 0 3.5em;
  background-color: white;
  min-height: 610px;
  max-height: 610px;

  .gp_guided-help__close-button {
    position: absolute;
    cursor: pointer;
    right: 25px;
    top: 25px;
  }

  .gp_guided-help__title {
    color: #010252;
    font-size: 1.3em;
    font-weight: 600;
    padding: 0 0 0.8em 2em;
    margin-top: -1em;
  }

  .gp_guided-help__body {
    padding: 2em 3.5em;
    border: solid 2px transparent;
    border-radius: 0.5em;
    background-color: #FFF8F9;
    position: relative;
    min-height: 446px;
    max-height: 446px;
    display: flex;

    .gp_guided-help__body--card-wrapper {
      overflow: hidden;
      position: relative;
      flex: 1;
      display: inherit;
      opacity: 0;
      transition: all .3s linear;

      .gp_guided-help__body--card-scroll {
        position: relative;
        flex: 1;
        display: inherit;
        transition: all .5s linear;

        .gp_guided-help__body--card {
          width: 100%;
          height: 100%;
          flex: 0 0 auto;
          z-index: 99;
        }
      }
    }

    .gp_guided-help__body--svg-bot {
      position: absolute;
      right: 1.5em;
      top: -3.5em;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }

    .gp_guided-help__body--svg-go-previous {
      position: absolute;
      left: -2em;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      cursor: pointer;
      transition: all .150s;
    }

    .gp_guided-help__body--svg-go-next {
      position: absolute;
      right: -2em;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      cursor: pointer;
      transition: all .150s;
    }
  }

  .gp_guided-help__footer {
    padding: 2em 12em 0 12em;

    .gp_guided-help__stepper-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      overflow: hidden;

      &:before {
        content: '';
        border: solid 1px rgb(0 0 0 / 10%);
        width: 100%;
        position: absolute;
        z-index: 0;
      }

      .gp_guided-help__stepper-progress {
        content: '';
        border: solid 2px #F93549;
        width: 100%;
        position: absolute;
        z-index: 1;
        transition: all .5s;
      }

      .gp_guided-help__stepper-point {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background-color: #5597E3;
        z-index: 1;
        cursor: pointer;

        &.current {
          background-color: #F93549;
        }

        &.done {
          background-color: #F93549;

          &.done:before {
            content: ""; /* Could be ✔ */
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 0.8em;
            line-height: 1.6em;
          }
        }
      }
    }
  }

  .bot-enter-active {
    animation: bot-in 0.5s;
  }

  .bot-leave-active {
    animation: bot-out 0.5s;
  }

  @keyframes bot-in {
    0% {
      opacity: 0;
      transform: translateY(-150px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  @keyframes bot-out {
    0% {
      opacity: 1;
      transform: translateY(0px);
    }
    100% {
      opacity: 0;
      transform: translateY(30px);
    }
  }
}
</style>