<template lang="pug">
  .filter-top.d-flex.flex-wrap
    v-col.col-brand.pt-0.pl-0
      v-select.pt-0(v-model="brandSelect" :items="brands" :placeholder="placeholder.brand"  color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" @change="changeBrand")
        template(slot="append")
          v-icon(color="terciary") mdi-chevron-down
        template(v-slot:selection="{ item, index }")
            img(:src="item.logo" width="41" height="22") 
            span.terciary--text.text-uppercase {{ item.name }}
        template(v-slot:item="{ item }")
            img.mr-2.mb-2.img-option(:src="item.logo" width="79" height="42") 
            span.option-filter-brand {{ item.name }}
    v-col.col-province.pt-0.pl-0(v-if="type !== 'provincesRank'")
      v-select.pt-0(v-model="filters.province" :items="provinces" item-text="province_name" return-object :placeholder="placeholder.province" no-data-text="no hay provincias para esta marca" color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" :disabled="!brandSelect" @change="changeFilter('province')" clearable)
        template(slot="append")
          v-icon(color="terciary") mdi-chevron-down
        template(v-slot:selection="{ item, index }")
            span.terciary--text.text-uppercase.text-elipsis {{ item.province_name }}
        template(v-slot:item="{item }")
            span.option-filter( :class="{'selected' : filters.province.province_name === item.province_name}") {{ item.province_name }}
    v-col.col-location.pt-0.pl-0(v-if="type !== 'provincesRank'")
      v-select.pt-0(v-model="filters.city" :items="cities" item-text="city_name" return-object :placeholder="placeholder.location" no-data-text="no hay ciudades para esta provincia"  color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" @change="changeFilter('city')" :disabled="!filters.province" clearable)
        template(slot="append")
          v-icon(color="terciary") mdi-chevron-down
        template(v-slot:selection="{ item, index }")
            span.terciary--text.text-uppercase.text-elipsis {{ item.city_name }}
        template(v-slot:item="{ item }")
            span.option-filter {{ item.city_name }} ({{ item.center_count }})
    v-col.col-cp.pt-0.pl-0(v-if="type !== 'provincesRank'")
      v-select.pt-0(v-model="filters.municipality" :items="cps" item-text="municipality_postal_code" return-object :placeholder="placeholder.postal_code" no-data-text="no hay códigos postales para esta ciudad" color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" @change="changeFilter('municipality')" :disabled="!filters.city" clearable)
        template(slot="append")
          v-icon(color="terciary") mdi-chevron-down
        template(v-slot:selection="{ item, index }")
            span.terciary--text.text-uppercase {{ item.municipality_postal_code }}
        template(v-slot:item="{ item }")
            span.option-filter {{ item.municipality_postal_code }}
    v-col.col-family.pt-0.pl-0
      v-select.pt-0(v-model="filters.family" :items="families" item-text="name" return-object :placeholder="placeholder.family" no-data-text="no hay familias" color="terciary" item-color="#ECF2F9"  @change="changeFilter('family')" :menu-props="{offsetY: true }" clearable)
        template(slot="append")
          v-icon(color="terciary") mdi-chevron-down
        template(v-slot:selection="{ item, index }")
            span(v-if="item").terciary--text.text-uppercase.text-elipsis {{ item.name }}
        template(v-slot:item="{item }")
            span.option-filter(v-if="item" :class="{'selected' : filters.family.name === item.name}") {{ item.name }}
    v-col.col-channel.pt-0.pl-0(v-if="type!=='aggregators'")
      v-select.pt-0(v-model="filters.channel" :items="channels" item-text="name" return-object :placeholder="placeholder.channel"  color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" :disabled="!brandSelect" @change="changeFilter('channel')" clearable)
        template(slot="append")
          v-icon(color="terciary") mdi-chevron-down
        template(v-slot:selection="{ item, index }")
            span.terciary--text.text-uppercase.text-elipsis {{ item.name }}
        template(v-slot:item="{ item }")
            span.option-filter {{ item.name }}
    v-col.col-aggregator.pt-0.pl-0(v-if="type==='comparator' || type === 'provincesRank'")
      v-select.pt-0(v-model="filters.aggregator" :items="aggregators" item-text="name" return-object :placeholder="placeholder.Aggregator"  color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" :disabled="!brandSelect || filters.channel.name !== 'Delivery'" @change="changeFilter('aggregator')" clearable)
        template(slot="append")
          v-icon(color="terciary") mdi-chevron-down
        template(v-slot:selection="{ item, index }")
            span.terciary--text.text-uppercase {{ item.name }}
        template(v-slot:item="{ item }")
            span.option-filter {{ item.name }}
    v-col.col-aggregator.pt-0.pl-0(v-if="type==='comparator' || type === 'provincesRank'")
      filter-search
</template>

<script>
import SrvDashboard from '@/services/dashboard.js'
import FilterSearch from '@/components/dashboard/filter-search.vue'
export default {
    props: ['type'],
    components: { FilterSearch },
    data (){
      return{
        brands: [],
        provinces: [],
        cities: [],
        cps: [],
        families: [],
        channels: [],
        aggregators: [],
        // channels: [{id: 1, name: 'EN SALA'},{id: 2, name:  'DELIVERY'}],
        filters:{
          province:'',
          family:'',
          channel: '',
          aggregator: '',
        },
        brandSelect: '',
        
        placeholder:{
          brand: this.$gettext('BRAND'),
          province: this.$gettext('PROVINCE'),
          location: this.$gettext('LOCATION'),
          postal_code: this.$gettext('POSTAL CODE'),
          family: this.$gettext('FAMILY'),
          channel: this.$gettext('CHANNEL'),
          Aggregator: this.$gettext('AGGREGATORS')
        }
      }
    },
    created() {
      if(!this.$store.getters.hasBrand) {
        SrvDashboard.getCountries().then(res => {
            if (res.status === 200) {
              const result = res.data.filter(c => c.code == window.localStorage.getItem('_country_selected'))
              if(result.length > 0){
                window.localStorage.setItem('_country_selected',result[0].code)
              } else {
                window.localStorage.setItem('_country_selected',res.data[0].code)
              }
              window.localStorage.setItem('_countries',JSON.stringify(res.data))  
              this.getBrands()
            }
        })
        
      } else {
        this.brands = this.$store.getters.hasBrands
        this.brandSelect = this.$store.getters.hasBrand
        this.provinces = this.$store.getters.hasProvinces
        this.channels = this.$store.getters.hasChannels
        if(this.$store.getters.hasFamilies === ''){
          this.getFamilies()
        }
        this.families = this.$store.getters.hasFamilies
        this.aggregators = this.$store.getters.hasAggregators
        if(this.$store.getters.hasProvince) {
          this.filters.province = this.$store.getters.hasProvince
          this.cities = this.$store.getters.hasCities
          if(this.$store.getters.hasCity) {
            this.filters.city = this.$store.getters.hasCity
            this.cps = this.$store.getters.hasPostalCodes
            if(this.$store.getters.hasPostalCode){
              this.filters.municipality = this.$store.getters.hasPostalCode
            }
          }
        }
        if(this.$store.getters.hasFamily){
          this.filters.family = this.$store.getters.hasFamily
        }
        if(this.$store.getters.hasChannel){
          this.filters.channel = this.$store.getters.hasChannel
        }
        if(this.$store.getters.hasAggregator){
          this.filters.aggregator = this.$store.getters.hasAggregator
        }
        this.$emit("getData", {brand: this.$store.getters.hasBrand.id, filters: this.filters});

      }

    },
    methods:{
      async getBrands(){
        SrvDashboard.getBrands()
        .then(async res => {
            if (res.status === 200) {
              this.brands = res.data.brands
              this.$store.commit('BRANDS', res.data.brands)
              if(this.brands.length > 0) {
                if (!this.$route.query?.brand) {
                  this.$router.replace({query: {brand:  this.brands[0].id}})
                  this.brandSelect = this.brands[0]
                  this.$store.commit('BRAND_SELECT', this.brandSelect)
                } else {
                  //FILTRAMOS POR LA MARCA DE URL
                  let brandurl = this.brands.filter( br => br.id == this.$route.query.brand)
                  this.brandSelect = brandurl[0]
                  this.$store.commit('BRAND_SELECT', this.brandSelect)
                }
                this.getProvinces(this.brandSelect.id)
                this.getFamilies(this.brandSelect.id)

                this.filters.channel = await this.getChannels(true)
                this.getAggregators()

                this.$emit("getData",{brand: this.brandSelect.id, filters: this.filters});
              }
            } 
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
      },
      getProvinces(brand){
        SrvDashboard.getProvinces(brand)
        .then(res => {
            if (res.status === 200) {
              this.provinces = res.data
              this.$store.commit('PROVINCES', res.data)

            } 
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
      },
      getCities(province){
        SrvDashboard.getCities(this.brandSelect.id, province.province_id)
        .then(res => {
            if (res.status === 200) {
              this.cities = res.data
              this.$store.commit('CITIES', res.data)
            } 
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
      },
      getCps(city){
        SrvDashboard.getCps(this.brandSelect.id, city.city_id)
        .then(res => {
            if (res.status === 200) {
              this.cps = res.data
              this.$store.commit('POSTAL_CODES', res.data)
            } 
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
      },
      async getFamilies(brand){
        let valAwait = ''
        await SrvDashboard.getFamilies(brand)
        .then(res => {
            if (res.status === 200) {
              this.families = res.data
              this.$store.commit('FAMILIES', res.data)
              valAwait = res.data
              // this.families.forEach(element => {
              //   if(element.id === 2){
              //     this.familySelect = element
              //   }
              // });
            } 
          }, () => {
          })
          return valAwait
      },
      async getChannels(first=false){
        let valAwait = ''
        await SrvDashboard.getChannels()
        .then(res => {
            if (res.status === 200) {
              this.channels = res.data
              this.$store.commit('CHANNELS', res.data)
              if(first){
                res.data.forEach(element => {
                  if(element.name==='Delivery'){
                    this.$store.commit('CHANNEL_SELECT', element)
                    valAwait = element
                  }
                })
              }
            } 
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
          return valAwait
      },
      
      getAggregators(){
        SrvDashboard.getAggregators()
        .then(res => {
            if (res.status === 200) {
              this.aggregators = res.data
              this.$store.commit('AGGREGATORS', res.data)
            } 
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
      },
      
      changeBrand() {
        this.$store.commit('BRAND_SELECT', this.brandSelect)      
        this.$router.replace({query: {brand:  this.brandSelect.id}})  
        // LIMPIAR FILTROS
        this.provinces = []
        this.families = [],
        this.$store.commit('PROVINCE_SELECT', '')
        this.$store.commit('FAMILY_SELECT', '')
        this.$store.commit('CHANNEL_SELECT', '')
        this.$store.commit('AGGREGATOR_SELECT', '')
        this.filters.province = ''
        this.filters.family = ''
        this.filters.channel = ''
        this.filters.aggregator = ''
        this.getFamilies(this.brandSelect.id)
        this.getProvinces(this.brandSelect.id)
        this.$emit("getData", {brand: this.brandSelect.id, filters: this.filters})
      },
      changeFilter(type) {
        if(this.filters[type] === null) {
          this.filters[type] = ''
        }
        if(type === 'province') {
          if(this.filters.province){
            this.getCities(this.filters.province)
          }
          this.filters.city = ''
          this.filters.municipality = ''
          this.cps = []
          this.$store.commit('POSTAL_CODES', [])
        }
        if(type === 'city') {
          if(this.filters.city){
            this.getCps(this.filters.city)
          }
          this.filters.municipality = ''
        }
        this.$store.commit('PROVINCE_SELECT', this.filters.province)
        this.$store.commit('CITY_SELECT', this.filters.city)
        this.$store.commit('POSTAL_CODE_SELECT', this.filters.municipality)
        this.$store.commit('FAMILY_SELECT', this.filters.family)
        this.$store.commit('CHANNEL_SELECT', this.filters.channel)
        this.$store.commit('AGGREGATOR_SELECT', this.filters.aggregator)

        this.$emit("getData", {brand: this.brandSelect.id, filters: this.filters});
      }
    } 
}
</script>

<style lang="scss" scoped>
.filter-top{
  
  .col-brand{
    flex: 0 0 230px;
    max-width: 230px;
   
  }
  .col-province{
    flex: 0 0 170px;
    max-width: 170px;
  }
  .col-location{
    flex: 0 0 140px;
    max-width: 140px;
  }
  .col-cp{
    flex: 0 0 160px;
    max-width: 160px;
  }
  .col-family{
    flex: 0 0 160px;
    max-width: 160px;
  }
  .col-channel{
    flex: 0 0 152px;
    max-width: 152px;
  }
  .col-aggregator{
    flex: 0 0 172px;
    max-width: 172px;
    .v-select__selections{
      span{
        width: 94%;
      }
    }
  }
  .col{
    span{
      font-size: 14px;
      letter-spacing: -0.5px;
    }
  }
  .v-input{
    .v-select__slot span {
      padding-left: 0;
    }
  }
}
</style>
