<template lang="pug">
  v-container.login.pa-0.heigth-100(fluid)
    v-row.align-center
      v-col.d-none.d-md-flex.heigth-100(cols="5")
        v-img.heigth-100(:src="img.background_login")
      v-col.pt-10(cols="12" md="7")
        v-row.flex-column.align-center.px-10.text-center
          v-img(:src="img.logo" max-width="335")
          p.cwhite.mt-8 
            strong #[translate Do not you remember your password?]
            p.cwhite #[translate No problem! ;-)]
            p.cwhite #[translate Tell us your email and we will send you a link so you can retrieve it.]
          .blockError
            p.pt-1(v-if="errorMsg") {{errorMsg}}
            p.cwhite(v-if="this.statusOk") {{ okMsg }}
          form#form_login
            div.mb-8
              v-text-field.field-login(v-model="email" :placeholder="placeholder.email" @focus="resetError()")
            v-row#recordarmeyOlvidado
                v-col.text-left(md="5")
                  div
                    button.cwhite(@click="home") < #[translate Return]
                v-col.text-right(md="7")
            div.text-center
              v-btn.mb-0(color="primary" type="button" width="185" height="50" @click="send" @keyup.enter="send") #[translate Send]
</template>

<script>
import SrvLogin from '@/services/login.js';

export default {
  name: 'Home',
  data() {
    return {
      dialog: false,
      img: {
        background_login: require('@/assets/img/auth/fondo.png'),
        logo: require('@/assets/img/auth/logo.png'),
      },
      error: null,
      email: null,
      errorMsg: false,
      errorEmail: false,
      errorPwd: false,
      errorMsgShow: false,
      statusOk: false,
      okMsg: false,
      placeholder: {
        'email': this.$gettext('Email'),
      }
    }
  },
  created() {
    if(window.localStorage.getItem("_token") || window.sessionStorage.getItem("_token")) {      
      // INTENTAR HACER LOGIN
      this.autoLogin()
    }
    if(this.$route.query.token) {
      this.token = this.$route.query.token
      window.localStorage.setItem('_token', this.token)
      this.autoLogin()
    } 
  },
  components: {
  },
  methods: {
    send () {
      let email = this.email
      this.resetError()

      let RegEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,4})+$/
      if (email === null || email === '' || !email.match(RegEx)) {
        this.errorMsg = true
        this.errorEmail = true
      }

      if (!this.errorMsg) {
          SrvLogin.restorePassword({email: email})
          .then(res => {
            this.statusOk = true
            this.okMsg = this.$gettext('check your email for restore password')
            console.log(res)
          }, (error) => {
            this.errorMsgShow = true
            if (error.response.data.email) {
              this.errorMsg = error.response.data.email[0]
            } else {
              this.errorMsg = this.$gettext('Error! incorrect data')
            }
          })
      }
    },
    home () {
        this.$router.push({name: 'home'})
    },
    resetError () {
      this.errorMsgShow = false
      this.errorEmail = false
      this.errorMsg = false
    },
    autoLogin () {
      /* this.$store.commit('PENDING') // Para mostrar el preload
      this.$store.commit('LOGIN_SUCCESS')
      this.$store.commit('NOT_PENDING') // Para quitar el preload */
      this.$router.push({name: 'rateAnalysis'})
    },
  }
}
</script>
<style lang="scss">
  .login{
    .subtitle{
      font-family: 'Playfair Display';
      font-size: 18px;
    }
    #form_login{
      width:100%;
      max-width: 500px;
      .field-login{
        .v-label{
          color: #000;
          font-size: 15px;
        }
        input{
          font-size: 15px;
          &::placeholder{
            color:#000;
          }
        }
      }
      .remember{
        .v-label{
          color: var(--v-secondary-base);
          font-size: 14px;
        }
      }
      .forgot-link{
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
//   .login{
//     .invitation{
//       color:#0D6AFF;
//       cursor: pointer;
//       margin-top: 12px;
//     }
//     .copy{
//       font-size: 10px;
//     }
//   }
//   .container.dialog-container{
//     max-width: 400px;
//     h2{
//       font-family: 'Roboto Slab';
//       font-size: 22px;
//       color: #003A70;
//       font-weight: bold;
//     }
//     .field-modal{
//       .v-label{
//         color: var(--v-disposable_gray-base);
//         font-size: 15px;
//       }
//       input{
//         font-size: 15px;
//       }
//     }
//     .accepting{
//       font-size: 12px;
//       color: #43425D;
//     }
//   }
.blockError{
  height: 35px;
  p{
    color:#EC4444
  }
}
</style>