// Stores
// import Stores from '@/store/index'
// // importamos los tipos de mutación de vuex
// import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'

//Store
import store from '@/store/index'

// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export default {
  getCountries () {
    let token = window.localStorage.getItem('_token') || window.sessionStorage.getItem('_token')
    axios.defaults.headers.common['Authorization'] = 'Token ' + token
    return new Promise((resolve, reject) => {
      axios.get('/user-countries/')
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getBrands () {
    axios.defaults.headers.common['Authorization'] = 'Token ' + store.state.user.hasToken
    let params = {}
    params.country = window.localStorage.getItem('_country_selected')
    return new Promise((resolve, reject) => {
      axios.get('/user-brands/',{params})
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getProvinces (brand) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + store.state.user.hasToken
    let params = {}
    params.country = window.localStorage.getItem('_country_selected')
    return new Promise((resolve, reject) => {
      axios.get('/brand-province/'+ brand + '/',{params})
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getCities (brand, province) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + store.state.user.hasToken
    let country = ''    
    country = '&country='+window.localStorage.getItem('_country_selected')
    return new Promise((resolve, reject) => {
      axios.get('/brand-city/' + brand + '/?province=' + province + country )
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getCps (brand, city) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + store.state.user.hasToken
    let country = ''
    country = '&country='+window.localStorage.getItem('_country_selected')
    return new Promise((resolve, reject) => {
      axios.get('/brand-postal-code/' + brand + '/?city=' + city + country)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getChannels () {
    axios.defaults.headers.common['Authorization'] = 'Token ' + store.state.user.hasToken
    let params = {}
    params.country = window.localStorage.getItem('_country_selected')
    return new Promise((resolve, reject) => {
      axios.get('/channel/',{params})
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getMoments (brand) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + store.state.user.hasToken
    let params = {}
    params.country = window.localStorage.getItem('_country_selected')
    return new Promise((resolve, reject) => {
      axios.get('/moment-of-consumption/' + brand + '/', {params})
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getAggregators () {
    axios.defaults.headers.common['Authorization'] = 'Token ' + store.state.user.hasToken
    let params = {}
    params.country = window.localStorage.getItem('_country_selected')
    return new Promise((resolve, reject) => {
      axios.get('/delivery-platform/',{params})
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getFamilies (brand) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + store.state.user.hasToken
    let params = {}
    params.country = window.localStorage.getItem('_country_selected')
    return new Promise((resolve, reject) => {     
      axios.get('/brand-family/' + brand + '/',{params})
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getDashData(page, brand, filters, ordering = '') {
    let params = {}
    if(filters.province.province_id){
      params.province = filters.province.province_id
    }
    if(filters.city.city_id){
      params.city = filters.city.city_id
    }
    if(filters.moment_of_consumption.id){
      params.moment_of_consumption = filters.moment_of_consumption.id
    }
    if(filters.municipality.municipality_id){
      params.municipality = filters.municipality.municipality_id
    }
    if(filters.channel.id){
      params.channel = filters.channel.id
    }
    if(filters.aggregator.id){
      params.delivery_platform = filters.aggregator.id
    }
    if(filters.family){
      params.family = filters.family
    }
    if(ordering) {
      params.ordering = ordering
    }
    params.country = window.localStorage.getItem('_country_selected')
    return new Promise((resolve, reject) => {
      axios.get('/'+ page + '/'+ brand + '/',{ params })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
           reject(error.response)
        })
    })
  },


}