// Importamos mutaciones
import * as types from '@/store/mutation-types'


const user = {
  state: {
    brands: '',
    provinces: '',
    cities: '',
    postal_codes: '',
    channels: '',
    moments:'',
    families:'',
    aggregators:'',
    brand_select: '',
    province_select: '',
    family_select: '',
    city_select: '',
    postal_code_select: '',
    moment_select: '',
    channel_select: '',
    aggregator_select: '',
    search_text: '',
  },
  actions: {
    // loadFilters ({commit}, filters) {
    //   commit(types.HAS_FILTERS, filters)
    // },
  },
  mutations: {
    [types.BRANDS] (state, brands ) {
      state.brands = brands
    },
    [types.BRAND_SELECT] (state, brand ) {
      state.brand_select = brand
    },
    [types.PROVINCES] (state, provinces ) {
      state.provinces = provinces
    },
    [types.PROVINCE_SELECT] (state, province ) {
      state.province_select = province
    },
    [types.FAMILIES] (state, families ) {
      state.families = families
    },
    [types.FAMILY_SELECT] (state, family ) {
      state.family_select = family
    },
    [types.CITIES] (state, cities ) {
      state.cities = cities
    },
    [types.CITY_SELECT] (state, city ) {
      state.city_select = city
    },
    [types.POSTAL_CODES] (state, postal_codes ) {
      state.postal_codes = postal_codes
    },
    [types.POSTAL_CODE_SELECT] (state, postal_code ) {
      state.postal_code_select = postal_code
    },
    [types.MOMENTS] (state, moments ) {
      state.moments = moments
    },
    [types.MOMENT_SELECT] (state, moment ) {
      state.moment_select = moment
    },
    [types.CHANNELS] (state, channel ) {
      state.channels = channel
    },
    [types.CHANNEL_SELECT] (state, channel ) {
      state.channel_select = channel
    },
    [types.AGGREGATORS] (state, aggregators ) {
      state.aggregators = aggregators
    },
    [types.AGGREGATOR_SELECT] (state, aggregator ) {
      state.aggregator_select = aggregator
    },
    [types.SEARCH_TEXT] (state, search ) {
      state.search_text = search
    },
  },
  getters: {
    hasBrands: state => {
      return state.brands
    },
    hasBrand: state => {
      return state.brand_select
    },
    hasProvinces: state => {
      return state.provinces
    },
    hasProvince: state => {
      return state.province_select
    },
    hasFamilies: state => {
      return state.families
    },
    hasFamily: state => {
      return state.family_select
    },
    hasCities: state => {
      return state.cities
    },
    hasCity: state => {
      return state.city_select
    },
    hasPostalCodes: state => {
      return state.postal_codes
    },
    hasPostalCode: state => {
      return state.postal_code_select
    },
    hasMoment: state => {
      return state.moment_select
    },
    hasMoments: state => {
      return state.moments
    },
    hasChannels: state => {
      return state.channels
    },
    hasChannel: state => {
      return state.channel_select
    },
    hasAggregators: state => {
      return state.aggregators
    },
    hasAggregator: state => {
      return state.aggregator_select
    },
    hasSearch: state => {
      return state.search_text
    }
  }
}

export default user
