// Importamos mutaciones
import * as types from '@/store/mutation-types'


const user = {
  state: {
    loading: '',
    titleHeader: '',
    hasMenu: '',
  },
  actions: {

  },
  mutations: {
    [types.LOADING] (state, payload ) {
      state.loading = payload
    },
    [types.TITLE_HEADER] (state, payload ) {
      state.titleHeader = payload
    },
    [types.HAS_MENU] (state, payload ) {
      state.hasMenu = payload
    }
  },
  getters: {
    isLoading: state => {
      return state.loading
    },
    getTitle: state => {
      return state.titleHeader
    },
    hasMenu: state => {
      return state.hasMenu
    }
  }
}

export default user
