export default {
    install(Vue) {
        Vue.mixin({
            computed: {
                navigatorLanguage() {
                    let userLocale = navigator.languages ? navigator.languages[0] : (navigator.language || navigator.userLanguage)
                    userLocale = userLocale.split('-')
                    return userLocale[0]
                }
            }
        })
    }
}

