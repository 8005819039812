<template lang="pug">
div 
  gp-filter-top#filtersFixed(@getData="getIps")
  v-container.gastroprice-content.d-flex.flex-column(fluid)
    .block-content.row
      v-container.table-data.ma-5.pa-0(v-if="isLoading" fluid)
        v-skeleton-loader(type="table")
      v-container.table-data.ma-5.pa-0(v-if="!isLoading" fluid)
        .table-title.pa-4
          p.mb-0.pt-3 #[translate IPS - Standard Price Index]
        .table-content
          .header.d-flex(v-if="ipsData.table")
            v-col(v-for="(item, index) in ipsData.table.header" :class="{'myRestaurant': index == 2 || index == ipsData.table.header.length - 2}")
              .block-img(v-if="item" :style="{ backgroundImage: 'url(' + getImg(item.logo) + ')' }")
          .subheader.d-flex(v-if="ipsData.table")
            v-col.pb-0.pt-1
            v-col.pb-0.pt-1
              p.mb-1 #[translate Standard Consumption]
            v-col.pb-0.pt-1(v-if="index > 1 && index < ipsData.table.header.length - 2" :class="{'myRestaurant': index == 2}" v-for="(item, index) in ipsData.table.header")
              p.mb-1 #[translate Average PVP]
            v-col.pb-0.pt-1.col-average
              p.mb-1 #[translate Average Competence]
            v-col.pb-0.pt-1
              p.mb-1 #[translate Deviation]
              
          .content-item.d-flex.align-stretch(v-if="ipsDataRows && index + 1 < ipsDataRows.length" v-for="(ips, index) in ipsDataRows")
            v-col.d-flex.align-center(v-for="(item, index) in ips" :class="{'justify-center': index > 0, 'title-row': index == 0 , 'myRestaurant': index == 2 , 'col-average': index == ips.length - 2, 'col-deviation': index == ips.length - 1}")
              p.mb-0(v-if="index !== ips.length - 1") {{item | formatNumber}}
                span(v-if="item !== null && index > 1 && index < ips.length - 1") &nbsp; €
              p.mb-0(v-if="index === ips.length - 1" :class="getArrow(item)") {{item | formatNumber}}
                span(v-if="item !== null") &nbsp; %
                
          .content-ticket.d-flex.align-stretch
            v-col
            v-col.d-flex.justify-center.align-center.title-row
              p.mb-0(v-if="ipsDataRows[lineTicket]") {{titleRow[ipsDataRows[lineTicket][0]]}} 
            v-col.d-flex.justify-center.align-center(v-if="index > 1" v-for="(item, index) in ipsDataRows[lineTicket]" :class="{ 'myRestaurant': index == 2, 'col-average': index == ipsData.table.header.length - 2, 'col-deviation': index == ipsData.table.header.length - 1}")
              p.mb-0(v-if="index !== ipsDataRows[lineTicket].length - 1") {{item | formatNumber}}
                span(v-if="item !== null && index > 0 && index < ipsDataRows[lineTicket].length - 1") &nbsp; €
              p.mb-0(v-if="index === ipsDataRows[lineTicket].length - 1" :class="getArrow(item)") {{item | formatNumber}}
                span(v-if="item !== null") &nbsp; %
          .content-ref.d-flex
            p.mb-0.pa-3 #[translate Number of References per Client] {{clientRefs}}
</template>
<script>
import GpFilterTop from '@/components/dashboard/filter-top.vue'
import SrvDashboard from '@/services/dashboard.js'

export default {
  components: {GpFilterTop},
  name: 'ips',
  data() {
    return {
      ipsData: [],
      ipsDataRows: [],
      clientRefs: '',
      lineTicket: '',
      img:{
        no_img: require('@/assets/img/logos/restaurant_icon.png')
      },
      titleRow:{
        'MEAN_TICKET': this.$gettext('AVERAGE TICKET PER CUSTOMER'),
      },
      loading : false,
      titleFilter: this.$gettext('Average consumption indicator per customer'),
    }
  },
  created(){
    this.$store.dispatch('loadMixPanelEvent')
    if(!this.$store.getters.hasToken) {
      this.$store.dispatch('loadToken')
    }
    this.$store.commit('TITLE_HEADER', this.titleFilter)
    this.$store.commit('HAS_MENU', '')

  },
  computed: {
    isLoading(){
      return this.$store.getters.isLoading
    }
  },
  methods: {
    getIps(data){
      this.$store.commit('LOADING', true)
      SrvDashboard.getDashData('ips',data.brand, data.filters)
      .then(res => {
          if (res.status === 200) {
            this.ipsData = res.data
            this.ipsDataRows = res.data.table.rows
            this.lineTicket = this.ipsDataRows.length - 1
            this.clientRefs = res.data.refs_per_client
            this.$store.commit('LOADING', false)
          } 
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    getImg(img){
      return img ? img : this.img.no_img
    },
    getArrow(data){
      if( data > 0){
        return 'arrow-up'
      } else if(data < 0){
        return 'arrow-down'
      }
      return ''
    }
  },
  mounted () {
    let elementBody = document.querySelector('.gastroprice-content')
    elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight) + 'px;')
    this.$nextTick(() =>{
      window.addEventListener('resize', function () {
        let elementBody = document.querySelector('.gastroprice-content')
        elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight) + 'px;')
      })
    })
  }
}
</script>
<style lang="scss" scoped>
  .gastroprice-content{
    .content-item{
      // height: 44px;
      font-size: 14px;
      border-bottom: 1px solid #D9DFE8;
      /*:left.title-row{
        flex: 0 0 10%;
        max-width: 10%;
      }*/
      .title-row{
        overflow-wrap: anywhere;
      }
    }
    .col-deviation{
      position: relative;
      p{
        &.arrow-up{
          &:before{
            content: "\a";
            position: absolute;
            left: 20px;
            top: 40%;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 10px solid #E8523F;
          }
        }
        &.arrow-down{
          &:before{
            content: "\a";
            position: absolute;
            left: 20px;
            top: 40%;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 10px solid #43dd4b;
          }
        }
      }
    }
    .content-ticket{
      border-bottom: 1px solid #D9DFE8;
      background-color: #4a91e207;
      height: 70px;
      .title-row{
        position:relative;
        p{
          position: absolute;
          right: 10px;
          white-space: nowrap;
        }
      }
      p{
        font-weight: 500;
        font-size: 15px;
      }
      .myRestaurant{
        background-color: #F2F2F2;
        p{
          font-weight: 700;
        }
      }
      .col-average{
        p{
           font-weight: 700;
        }
      }
      .col-deviation{
        p{
           font-weight: 700;
        }
      }
    }
    .content-ref{
      font-size: 14px;
      height: 48px;
    }
  }

</style>