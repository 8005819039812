<template lang="pug">
    div.filters
        span.labelFilter
            div.filter.ml-2.mr-4
                input.filterBySearch(type="text" v-model="searchTemp" placeholder="Buscar...")
                button.filterBySearchButton.clean.mdi.mdi-close(@click="cleanBySearch" :class="{'hidden': searchTemp === ''}") 
                    span
                button.filterBySearchButton(@click="filterBySearch")
                    img(:src="img.search")    
</template>
<script>
import * as types from '@/store/mutation-types'

export default {
    data() {
        return {
            img: {
                search: require('@/assets/img/buscar.svg'),
                clean: require('@/assets/img/borrar.svg')
            },
            searchTemp: ''
        }
    },
    created () {
        this.searchTemp = this.$store.getters.hasSearch
    },
    computed: {
        search: {
            get () {
                return this.$store.getters.hasSearch
            },
            set (val) {
                this.searchTemp =  val
            }
        }
    },
    methods: {
        cleanBySearch () {
            this.searchTemp = ''
            this.$store.commit(types.SEARCH_TEXT,'')
        },
        filterBySearch () {
            this.$store.commit(types.SEARCH_TEXT,this.searchTemp)
        }
    },
    watch: {
        searchTemp (newVal) {
            if (newVal === '') {
                this.$store.commit(types.SEARCH_TEXT,'')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.filterBySearch{
  vertical-align: middle;
  font-size: 0.75rem;
  background: white;
  color:#1976d2;
  height: 34px;
  width: calc(100% - 50px);
  padding-left: 5px;
    &::placeholder {
        padding-left: 0;
    }
}
.filter{
    border-bottom:1px solid #1976d2;
    margin-top: 2px;
}
.filterBySearchButton{
  vertical-align: middle;
  background-color: #fff;
  border-left: 0;
  height: 34px;
  padding:0 5px;
  img{
    width: 14px;
    margin-top: 5px;
  }
}
.hidden{
    visibility: hidden;
}
</style>