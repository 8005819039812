<template lang="pug">
  v-app-bar.header.rounded-tl-xl(color="#fff" height="72" flat app)
    v-img(class="mx-2" :src="urlLogo" max-height="54" max-width="92")
    v-row
      v-col.d-flex.align-center(md="7")
        p.d-flex.align-center.mb-0.primary--text {{title}}
      v-col(md="4")
        div.d-flex.align-center.justify-end.gap-1em
          //- Show Guided Help button for routes 'ips', 'ipb', 'ipj'
          div.d-flex.justify-center.align-center.cursor-pointer(v-if="guidedHelpTypes.includes(currentRouteName) && ['es', 'en'].includes(navigatorLanguage)" @mousedown="showGuidedHelp")
            span.mr-2.text-caption.font-weight-medium.header__text-color {{ guidedHelpData[currentRouteName].buttonLabel[navigatorLanguage] }}
            svg-chevron-to-right.mr-2
            svg-bot-help-button.hvr-buzz-out
            guided-help(:visible.sync="guidedHelpVisible" :title="guidedHelpData[currentRouteName].title[navigatorLanguage]" :steps="guidedHelpData[currentRouteName].steps")
          .block-user.d-flex.mt-0.align-center
            v-menu(v-if="optionsTree.length > 1" transition="slide-y-transition" bottom offset-y z-index="999999")
              template(v-slot:activator="{ on, attrs }")
                v-btn#countryButton.selectCountry.mr-2.btn-lang.text-capitalize( v-bind="attrs" v-on="on" text tile small)
                  v-img.mr-2(:src="icoFlag(countrieCodActive)" width="15" height="15")
                  span {{countrieNameActive}}
                  //- v-icon(right color="primary") mdi-chevron-down
              v-list.text-right.list-options-tree
                v-list-item(v-if="countrieNameActive !== item.name" v-for="(item, i) in optionsTree" :key="i" @click="setCountry(item.code)")
                  v-list-item-title.text.text-left.d-flex
                    img(:src="icoFlag(item.code)" width="15" height="15" )
                    span {{ item.name }}
      v-col.d-flex.justify-center.align-center(md="1")
        v-img(class="cursor-pointer" :src="img.power" max-height="16" max-width="14" @click="logout")
    template(v-if="hasMenu" v-slot:extension)
      .block-link.pt-3(v-if="hasMenu === 'rate'")
        router-link.mx-4.menu-gp.text-decoration-none(:to="{name: 'rateComparator', query: $route.query}") #[translate Rate Comparator]
        router-link.mx-4.menu-gp.text-decoration-none(:to="{name: 'priceVariations', query: $route.query}") #[translate Price Variations]
        router-link.mx-4.menu-gp.text-decoration-none(:to="{name: 'aggregatorsRank', query: $route.query}") #[translate Aggregators Ranking]
        router-link.mx-4.menu-gp.text-decoration-none(:to="{name: 'provincesRank', query: $route.query}") #[translate Provinces Ranking]
      .block-link.pt-3(v-if="hasMenu === 'promotions'")
        router-link.mx-4.menu-gp.text-decoration-none(:to="{name: 'promoComparator', query: $route.query}")
          span #[translate Promo Comparator]
        router-link.mx-4.menu-gp.text-decoration-none(:to="{name: 'promoNews', query: $route.query}")
          span #[translate News]
        router-link.mx-4.menu-gp.text-decoration-none(:to="{name: 'promoFinished', query: $route.query}")
          span #[translate Finished]

</template>

<script>
import * as types from '@/store/mutation-types'
import SrvDashboard from '@/services/dashboard.js'

import appConfig from '@/config-app.js'
import SvgBotHelpButton from "../../assets/img/svg/svgBotHelpButton";
import SvgChevronToRight from "../../assets/img/svg/svgChevronToRight";
import GuidedHelp from "../guidedHelp/guidedHelp";

export default {
  components: {GuidedHelp, SvgChevronToRight, SvgBotHelpButton},
  data (){
      return{
        img: {
          power: require('@/assets/img/power.png')
        },
        guidedHelpVisible: false,
        guidedHelpTypes: ['ips', 'ipb', 'ipj', 'ipd'],
        guidedHelpData: {
          'ips': {
            title: {
              es:  'IPS. Índice de precios estándar',
              en:  'SACI. Standar Average Check Index'
            },
            buttonLabel: {
              es:  '¿Qué es IPS?',
              en:  'What is SACI?'
            },
            steps: [
              {  bot: 'assets/img/svg/svgBotModel_1', card: 'components/guidedHelp/IPS/Card_1' },
              {  bot: 'assets/img/svg/svgBotModel_2', card: 'components/guidedHelp/IPS/Card_2' },
              {  bot: 'assets/img/svg/svgBotModel_3', card: 'components/guidedHelp/IPS/Card_3' },
              {  bot: 'assets/img/svg/svgBotModel_4', card: 'components/guidedHelp/IPS/Card_4' }
            ]
          },
          'ipb': {
            title: {
              es:  'IPB. Índice de precios Básicos',
              en:  'CPI. Comodities Price Index'
            },
            buttonLabel: {
              es:  '¿Qué es IPB?',
              en:  'What is CPI?'
            },
            steps: [
              {  bot: 'assets/img/svg/svgBotModel_1', card: 'components/guidedHelp/IPB/Card_1' },
              {  bot: 'assets/img/svg/svgBotModel_2', card: 'components/guidedHelp/IPB/Card_2' },
              {  bot: 'assets/img/svg/svgBotModel_3', card: 'components/guidedHelp/IPB/Card_3' }
            ]
          },
          'ipj': {
            title: {
              es:  'IPJ. Índice de precios Ajustados',
              en:  'API. Adjusted Price Index'
            },
            buttonLabel: {
              es:  '¿Qué es IPJ?',
              en:  'What is API?'
            },
            steps: [
              {  bot: 'assets/img/svg/svgBotModel_1', card: 'components/guidedHelp/IPJ/Card_1' },
              {  bot: 'assets/img/svg/svgBotModel_2', card: 'components/guidedHelp/IPJ/Card_2' },
              {  bot: 'assets/img/svg/svgBotModel_3', card: 'components/guidedHelp/IPJ/Card_3' }
            ]
          },
          'ipd': {
            title: {
              es:  'IPD. Índice de dispersión de Precios',
              en:  'RPI. Ranged Prices Index'
            },
            buttonLabel: {
              es:  '¿Qué es IPD?',
              en:  'What is RPI?'
            },
            steps: [
              {  bot: 'assets/img/svg/svgBotModel_1', card: 'components/guidedHelp/IPD/Card_1' },
              {  bot: 'assets/img/svg/svgBotModel_2', card: 'components/guidedHelp/IPD/Card_2' },
              {  bot: 'assets/img/svg/svgBotModel_3', card: 'components/guidedHelp/IPD/Card_3' },
              {  bot: 'assets/img/svg/svgBotModel_4', card: 'components/guidedHelp/IPD/Card_4' }
            ]
          }
        },
      }
    },
    created () {
      if (!document.getElementById('scriptMixPanel')) {

        const scriptLine = document.createElement('script')
        scriptLine.id = 'scriptMixPanel'
        let usuario = ''
        if (window.sessionStorage.getItem("_user")) {
          usuario = window.sessionStorage.getItem("_user")
        }
        if (window.localStorage.getItem("_user")) {
          usuario = window.localStorage.getItem("_user")
        }
        scriptLine.text = `(function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
MIXPANEL_CUSTOM_LIB_URL:"file:"===f.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^/)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);
mixpanel.init("a5347dc77ff080064b1b0d77e4b71b77", {batch_requests: true})
        /* Identify User */
        mixpanel.identify('${usuario}');

        /* Track Navigation Event */
        mixpanel.track('Navigation Event');
        `
        document.head.appendChild(scriptLine)
      }
    },
    computed: {
      countrieNameActive () {
        let country = this.optionsTree.filter(c => c.code === this.countrieCodActive);
        return country[0].name
      },
      countrieCodActive () {
        let cod = window.localStorage.getItem('_country_selected')
        return cod
      },
      optionsTree(){
        // SI YA TENIAN TOKEN Y NO SE HABIAN CARGADO LOS PAISES
        if (!window.localStorage.getItem('_countries')) {
          SrvDashboard.getCountries().then(res => {
            window.localStorage.setItem('_country_selected',res.data[0].code)
            window.localStorage.setItem('_countries',JSON.stringify(res.data))
            location.reload()
          }, (error) => {
            this.errorMsg = error.data.non_field_errors[0]
          })

        }
        return JSON.parse(window.localStorage.getItem('_countries'))
      },
      title() {
        return this.$store.getters.getTitle
      },
      hasMenu() {
        return this.$store.getters.hasMenu
      },
      urlLogo() {
        return require("@/assets/img/logos/logo_"+ appConfig.logo +".png")
      },
      currentRouteName() {
        return this.$route.name;
      }
    },
    methods:{
      setCountry (code){
        window.localStorage.setItem('_country_selected', code)
        this.$store.commit(types.COUNTRY_SELECTED,code)
        location.reload();
        //this.$router.push({name: 'rateAnalysis' })
      },
      icoFlag (code) {
        return require('@/assets/img/flags/'+ code.toString().toLowerCase() +'.svg')
      },
      logout() {
        if(window.localStorage.getItem('_token')){
          window.localStorage.removeItem('_token')
          window.localStorage.removeItem('_refresh')
        }else if(window.sessionStorage.getItem('_token')){
          window.sessionStorage.removeItem('_token')
          window.sessionStorage.removeItem('_refresh')
        }
        this.$router.push({name: 'home'})
      },
      showGuidedHelp() {
        this.guidedHelpVisible = true;
      }
    }
}
</script>

<style lang="scss" scoped>
  .header{
    border-bottom: 2px solid #9B9B9B;
    border-color: #9b9b9b14!important;
    transform-style: preserve-3d;
    /*:not()&:after{
      content: "";
      width: 30px;
      height: 30px;
      background-color: var(--v-background-base);
      position: absolute;
      top: 0;
      transform: translateZ(-1px);
    }*/
    .menu-gp{
      color:#4A4A4A;
      font-size: 14px;
    }
    .selectCountry{
      height: auto;
    }
    .gap-1em {
      column-gap: 1em;
    }
  }
  .vertical-middle{
    vertical-align: middle;
  }
</style>
