export default {
    methods: {
        getImageAggregator(aggregator = ''){
            console.log(aggregator)
            let aggregatorLoweCase = ''
            if (aggregator) {
              aggregatorLoweCase = aggregator.toLowerCase()
            }
            if(aggregatorLoweCase === 'deliveroo'){
              return require("@/assets/img/aggregators/Deliveroo.png")
            } else if (aggregatorLoweCase === "glovo"){
              return require("@/assets/img/aggregators/Glovo.png")
            } else if (aggregatorLoweCase === "just eat"){
              return require("@/assets/img/aggregators/Just Eat.png")
            } else if (aggregatorLoweCase === "uber eats"){
              return require("@/assets/img/aggregators/Uber Eats.png")
            } else if (aggregatorLoweCase === "bolt food"){
              return require("@/assets/img/aggregators/Bolt Food.png")
            } else if (aggregatorLoweCase === "takeaway.com"){
              return require("@/assets/img/aggregators/Takeaway.com.png")
            } else if (aggregatorLoweCase === "marca"){
              return require("@/assets/img/aggregators/Marca.png")
            }
          }
    },
}