<template lang="pug">
  v-container.notFound(fluid :style="{ backgroundImage: 'url(' + img.background_img + ')' }")
    v-row.align-center.heigth-100
      v-col.d-flex.justify-center.align-center.flex-column(cols="12")
        .block-img.mb-9.mt-n16
          v-img(:src="img.error" width="96" height="64" countain ) 
        p.mb-0.white--text.oops oops! 404 error
        p.mb-16.white--text.principal Page not found
        a.link.text-decoration-none(href="/") Take me back to the home page

</template>

<script>

export default {
  name: 'notFound',
  data() {
    return {
      img: {
        background_img: require('@/assets/img/404/error_fondo.svg'),
        error: require('@/assets/img/404/error.svg')

      }
    }
  },
  created() {
    this.$store.dispatch('loadMixPanelEvent')
  },
  components: {
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.notFound{
  background-color: var(--v-secondary-base);
  background-position: center;
  .oops{
    font-size: 26px;
  }
  .principal{
    font-family: 'Playfair Display';
    font-size: 38px;
  }
  .link{
    color: #4398FB;
    font-size: 18px;
    &:hover{
      color:#1c85fe;
    }
  }
}
</style>