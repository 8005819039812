<template lang="pug">
    v-app.myFont(dark)
      div#gastroprice.bg-app.heigth-100(v-if="$route.name !== 'home' && $route.name !== '404' && $route.name !== 'Restore'")
        gp-header
        //- gp-menu
        gp-sidebar
        v-main
          router-view
      div#gastroprice-login(v-else)
        router-view

</template>

<script>
import GpHeader from '@/components/dashboard/header.vue'
import GpSidebar from '@/components/dashboard/sidebar.vue'

export default {
  name: 'App',

  components: {
    GpHeader,GpSidebar
  },

  data: () => ({
    //
  }),
  created(){

  },
  methods: {
  
  }
};
</script>
<style lang="scss">
@import "./assets/sass/styles.scss";
</style>
