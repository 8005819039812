// Stores
// import Stores from '@/store/index'
// Config
import appConfig from '@/config-app.js'
// importamos los tipos de mutación de vuex
// import * as types from '@/store/mutation-types'

// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api_login
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export default {
  getToken(user) {
    return new Promise((resolve, reject) => {
      axios.post(appConfig.apiAuth + 'api-token-auth/', user)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  restorePassword (email) {
    return new Promise((resolve, reject) => {
      axios.post(appConfig.apiAuth + 'password_reset/', email)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}