<template lang="pug">
  router-view
</template>
<script>

export default {
  name: 'rateAnalysis',
  data() {
    return {
      titleFilter: this.$gettext('Análisis de Tarifas')

    }
  },
  created(){
    this.$store.dispatch('loadMixPanelEvent')
    if(!this.$store.getters.hasToken) {
      this.$store.dispatch('loadToken')
    }
    this.$store.commit('TITLE_HEADER', this.titleFilter)
    this.$store.commit('HAS_MENU', 'rate')
  },
  computed: {
  },
  methods: {

  }
}
</script>
