<template lang="pug">
  v-navigation-drawer.background.sidebar(width="128" permanent app)
    .block-img.d-flex.justify-center.align-center
      v-img(class="mx-2" :src="img.logo_dash" max-height="34" max-width="86")
    v-list.pa-0.bg-sidebar.list-sidebar(dense tile)
      v-list-item.px-0.py-1.border-b-app( active-class="link-active" two-line :to="{name: 'rateAnalysis', query: $route.query}")
        v-list-item-title.text-center 
          span #[translate.resalt RATE] #[br] #[translate.resalt ANALYSIS]
      v-list-item.px-0.py-1.border-b-app( active-class="link-active" two-line :to="{name: 'promotionsAnalysis', query: $route.query}")
        v-list-item-title.text-center 
          span #[translate.resalt ANALYSIS OF] #[br] #[translate.resalt PROMOTIONS]
      v-list-item.px-0.py-1.border-b-app(exact-active-class="link-active" two-line :to="{name: 'ips', query: $route.query}")
        v-list-item-title.text-center 
          span IPS
          br
          span #[translate INDEX PRICES] #[br] #[translate STANDARD]
      v-list-item.px-0.py-1.border-b-app(exact-active-class="link-active" two-line :to="{name: 'ipb', query: $route.query}")
        v-list-item-title.text-center
          span IPB
          br
          span #[translate INDEX PRICES] #[br] #[translate BASIC]
      v-list-item.px-0.py-1.border-b-app(exact-active-class="link-active" two-line :to="{name: 'ipj', query: $route.query}")
        v-list-item-title.text-center 
          span IPJ
          br
          span #[translate INDEX PRICES] #[br] #[translate ADJUSTED]
      v-list-item.px-0.py-1.border-b-app(exact-active-class="link-active" two-line :to="{name: 'ipd', query: $route.query}")
        v-list-item-title.text-center 
          span IPD 
          br
          span #[translate DISPERSION INDEX] #[br] #[translate OF PRICES]
      //v-list-item.px-0.py-1.border-b-app(exact-active-class="link-active" two-line to="/icd")
        v-list-item-title.text-center 
          span ICD 
          br
          span #[translate INDEX OF] #[br] #[translate COMPETENCE]
      
      //- v-list-item.px-0.py-1.border-b-app(exact-active-class="link-active" two-line to="/cpc")
      //-   v-list-item-title.text-center 
      //-     span CPC 
      //-     br
      //-     span COMPARACIÓN DE #[br] PRECIOS DE #[br] LA COMPETENCIA
    .block-img.d-flex.justify-center.align-center.border-b-app
      a(href="https://gastroranking.pro/")
        v-img(class="mx-2" :src="img.logo_gr" max-height="45" max-width="100")
</template>

<script>

export default {
    data (){
      return{
        img: {
          logo_dash: require('@/assets/img/logo_dash.svg'),
          logo_gr: require('@/assets/img/logo_gastroranking.png'),
        },
      }
    },   
    methods:{
      // logout() {
      //   if(window.localStorage.getItem('_token')){
      //     window.localStorage.removeItem('_token')
      //     window.localStorage.removeItem('_refresh')
      //   }else if(window.sessionStorage.getItem('_token')){
      //     window.sessionStorage.removeItem('_token')
      //     window.sessionStorage.removeItem('_refresh')
      //   }
      //   this.$router.push({name: 'home'})
      // }
    } 
}
</script>

<style lang="scss" scoped>
 .sidebar{
  .block-img{
    height: 72px;
  }
  .list-sidebar{
    .v-list-item--active{
      background-color:#04043A;
    }
    span{
      font-size: 11px;
      color:#fff;
    }
    .link-active{      
      .v-list-item__title{
        & > span:first-child {
          color: var(--v-pink_app-base);
        }
        .resalt{
          color: #dedede;
        }
      }
      &::before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 101%;
      width: 8px;
      background: var(--v-pink_app-base);
      opacity: 1;
      }
    }
  }
 }
</style>